import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getListadoCompaniasNotario, getListadoNotariosSinConfiguracion, getListarNotariosXCompania, listarConfiguracionNotario, registrarConfiguracionNotario } from "../../api/iofeApi";
import { inputSoloNumeros, obtenerLocalStorage } from "../../resources/funciones";
import Swal from "sweetalert2";
export const Notario = () => {
    const { handleSubmit, register, reset, formState: { errors }, watch } = useForm({});
    const [showModal, setShowModal] = useState(false);
    const [nombreNotario, setNombreNotario] = useState("");
    const [perIdSeleccionado, setPerIdSeleccionado] = useState(null);
    const [companias, setCompanias] = useState([]);
    const [empresaSeleccionada, setEmpresaSeleccionada] = useState('');
    const [empresaDescripcionSeleccionada, setEmpresaDescripcionSeleccionada] = useState('');
    const [resultado, setResultado] = useState([]);
    const [isAgregarConfiguracion, setIsAgregarConfiguracion] = useState(false);
    const token = obtenerLocalStorage("usuario").token;
    const [showButton, setShowButton] = useState(false);
    const [dataNSinConfiguracion, setDataNSinConfiguracion] = useState([]);
    const [tipoNotario, setTipoNotario] = useState("");
    const [notariosPrincipales, setNotariosPrincipales] = useState([]);
    const [defaultvalue, setDefaultvalue] = useState("")


    const handleEmpresaChange = (e) => {
        const empresa = e.target.value;
        const empresaSeleccionada = companias.find(compania => compania.value === empresa);
        setEmpresaSeleccionada(empresa);
        setEmpresaDescripcionSeleccionada(empresaSeleccionada.description);
    };
    const handleOpenModal = async (data = {}, isAgregar = false) => {
        if (isAgregar) {
            setIsAgregarConfiguracion(true);
            setNombreNotario("");
            setPerIdSeleccionado(null);
            reset({
                TipoDeNotario: "",
                FechaDesde: "",
                FechaHasta: "",
                CodNotario: "",
                Notario: ""
            });
            const dataPost = {
                compania: empresaSeleccionada
            };
            const response = await getListadoNotariosSinConfiguracion(dataPost, token);
            console.log("RESPONSE", response);

            if (response && response.data) {
                const filteredLincenciaNoRegistrados = response.data.filter(item => item.registrado === "N");
                console.log("filteredLincenciaNoRegistrados", filteredLincenciaNoRegistrados);

                setDataNSinConfiguracion(filteredLincenciaNoRegistrados);
                setTipoNotario(filteredLincenciaNoRegistrados[0]?.tipo_notario || "");
                const filteredPrincipales = response.data.filter(item => item.tipo_notario === "P");
                console.log("filteredPrincipales", filteredPrincipales);
                setNotariosPrincipales(filteredPrincipales)
            } else {
                console.error("Error fetching data:", response.message);
            }
        } else {
            if (!data || !data.nombre || !data.id) {
                console.error("Datos del notario no válidos:", data);
                return;
            }
            setIsAgregarConfiguracion(false);
            setNombreNotario(data.nombre);
            console.log("XD: ", data);

            setPerIdSeleccionado(data.id);
            const dataPost = {
                compania: empresaSeleccionada,
                notario: data.id
            };
            const response = await listarConfiguracionNotario(dataPost, token);
            const response2 = await getListadoNotariosSinConfiguracion(dataPost, token);
            const filteredPrincipales = response2.data.filter(item => item.tipo_notario === "P");
            setNotariosPrincipales(filteredPrincipales)
            setDefaultvalue(response.data[0].notario_r)
            if (response && response.data) {
                console.log("MIRA ESTE RESPONSE: ", response.data);
                reset({
                    TipNotario: data.TipNotario || "",
                    FechaDesde: response.data[0].fecha_desde || "",
                    FechaHasta: response.data[0].fecha_hasta || "",
                    CodNotario: response.data[0].codigos_notarios[0].value || "",
                    CodNotario2: response.data[0].codigos_notarios[1].value || "",
                    Notario: data.Notario || "",
                });
            } else {
                console.error("Error fetching data:", response.message);
            }
        }
        setShowModal(true);
    };
    const handleCloseModal = () => {

        setShowModal(false);
        reset();
    };
    const getCompanias = async () => {
        const { data } = await getListadoCompaniasNotario(token); //correcto
        setCompanias(data);
    };
    useEffect(() => {
        getCompanias();
    }, []);
    const buscar = async () => {
        const dataPost = { "compania": empresaSeleccionada };  //correcto
        const { data } = await getListarNotariosXCompania(dataPost, token);
        setResultado(data);
        console.log("MIRA  ESTA DATA", data);

        setShowButton(true);
    };
    const onSubmit = async (formData) => {
        const configuracion = [
            { id: "1", value: formData.CodNotario },
            { id: "2", value: formData.CodNotario2 },
        ];


        const dataPost = {
            compania: empresaSeleccionada,
            tipo_notario: formData.TipNotario,
            fecha_desde: formData.FechaDesde,
            fecha_hasta: formData.FechaHasta,
            notario: isAgregarConfiguracion ? formData.Notario : perIdSeleccionado,
            configuracion: configuracion,
            notaria_a_r: formData.NotARee || "",

        };
        console.log("DATAPOST", dataPost);

        try {
            const registro = await registrarConfiguracionNotario(dataPost, token);
            handleCloseModal();
            Swal.fire({
                title: "Hecho!",
                text: registro.message,
                icon: "success"
            });
        } catch (error) {
            console.error("Error al enviar la configuración:", error);
        }
    };
    return (
        <div className="container">
            <h1>Notario</h1>
            <div className="container p-3">
                <div className="row">
                    <div className="col-4">
                        <select name="empresa" onChange={handleEmpresaChange} className="form-select">
                            <option value="" selected disabled>Seleccione la compañía</option>
                            {companias.map((compania, index) => (
                                <option key={index} value={compania.value}>
                                    {compania.description}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <button className="btn btn-outline-primary w-50" onClick={buscar}><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
            <div className="container p-3">
                {showButton && (
                    <button type="button" className="btn btn-success" onClick={() => handleOpenModal({}, true)}>
                        Agregar Configuración
                    </button>
                )}
            </div>
            <div className="container">
                <div style={{ maxHeight: '400px', overflowY: 'auto', userSelect: "none" }}>
                    <table className="table text-center table-bordered">
                        <thead>
                            <tr>
                                {/* <th scope="col">Tipo de documento</th>
                                <th scope="col">N° Documento</th> */}
                                <th scope="col">Notaria</th>
                                <th scope="col">Tipo de notario</th>
                                <th scope="col">Fecha</th>
                                {/* <th scope="col">Fecha Hasta</th> */}
                                <th scope="col">Configuración</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resultado.map((fila, index) => (
                                <tr key={index}>
                                    {/* <td>{fila.doctipo === 'D' ? "DNI" : fila.doctipo === 'P' ? "Pasaporte" : "Carnet de extranjeria"}</td>
                                    <td>{fila.docnumero}</td> */}
                                    <td>{fila.nombre}</td>
                                    <td>{fila.TipNotario === "L" ? "Licencia" : "Principal"}</td>
                                    {
                                        fila.TipNotario === "L" ? (
                                            <td>{fila.desde} - {fila.hasta}</td>
                                        ) : (
                                            <td>-</td>
                                        )
                                    }

                                    {/* <td>{fila.nombre}</td> */}
                                    <td>
                                        <button className="btn btn-outline-primary" onClick={() => handleOpenModal(fila, false)}>
                                            <i className="bi bi-gear"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <>
                    <div className="modal-backdrop show"></div>
                    <div className="modal show" tabIndex="-1" style={{ display: "block" }} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Configuración de {nombreNotario.toLowerCase() || "un Notario"}</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="container">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row mb-3">
                                                <div className="col-5">
                                                    <label>Compañía</label>
                                                </div>
                                                <div className="col-7">
                                                    <p>{empresaDescripcionSeleccionada}</p>
                                                </div>
                                            </div>
                                            {isAgregarConfiguracion && (
                                                <>
                                                    <div className="row mb-3">
                                                        <div className="col-5">
                                                            <label>Notario</label>
                                                        </div>
                                                        <div className="col-7">
                                                            <select className="form-select" {...register('Notario', {
                                                                required: "Este campo es obligatorio"
                                                            })} >
                                                                <option value="" disabled selected>Seleccione un notario</option>
                                                                {dataNSinConfiguracion.map((rep, index) => (
                                                                    <option key={index} value={rep.notario}>{rep.nombre}</option>
                                                                ))}
                                                            </select>
                                                            {errors.Notario && <span className="text-danger">{errors.Notario.message}</span>}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="row">
                                                <div className="col-5">
                                                    <label>Tipo de Notario</label>
                                                </div>
                                                <div className="col-7 mb-3">
                                                    <select className="form-select" disabled={isAgregarConfiguracion === false} {...register('TipNotario', {
                                                        required: "Este campo es obligatorio"
                                                    })} >
                                                        <option value="" selected disabled>Seleccione el tipo de notario</option>
                                                        {/* {dataNSinConfiguracion.map((rep, index) => (
                                                            <option key={index} value={rep.id}>{rep.tipo_notario === "P" ? "Principal" : "Licencia"}</option>
                                                        ))} */}
                                                        <option value="P" >Principal</option>
                                                        <option value="L" >Licencia</option>
                                                    </select>
                                                    {errors.TipNotario && <span className="text-danger">{errors.TipNotario.message}</span>}
                                                    {/* <span>{tipoNotario === "L" ? "Lincencia" : "Principal"}</span> */}
                                                </div>
                                                {
                                                    watch("TipNotario") === 'L' && (
                                                        <>   <div className="col-5 mt-2">
                                                            <label>Notaria a reemplazar</label>
                                                        </div>
                                                            <div className="col-7 mt-2">
                                                                {/* <input type="text" className="form-control" onKeyDown={inputSoloNumeros} {...register('NotARee', {})} />
                                                                {errors.NotARee && <span className="text-danger">{errors.NotARee.message}</span>} */}
                                                                <select className="form-select" defaultValue={defaultvalue} name="" id="" {...register("NotARee")}>
                                                                    <option value="" disabled selected>Seleccione la notaria a reemplazar</option>
                                                                    {notariosPrincipales.map((rep, index) => (
                                                                        <option key={index} value={rep.notario}>{rep.nombre}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-5 mt-2">
                                                                <label>Fecha Desde</label>
                                                            </div><div className="col-7 mt-2">
                                                                <input type="text" className="form-control" /* onKeyDown={inputSoloNumeros} */ {...register('FechaDesde', {})} />
                                                                {errors.FechaDesde && <span className="text-danger">{errors.FechaDesde.message}</span>}
                                                            </div><div className="col-5 mt-2">
                                                                <label>Fecha Hasta</label>
                                                            </div><div className="col-7 mt-2">
                                                                <input type="text" className="form-control" /* onKeyDown={inputSoloNumeros} */ {...register('FechaHasta', {})} />
                                                                {errors.FechaHasta && <span className="text-danger">{errors.FechaHasta.message}</span>}
                                                            </div></>
                                                    )
                                                }
                                                <div className="col-5 mt-2">
                                                    <label>Codigo Notario</label>
                                                </div>
                                                <div className="col-7 mt-2">
                                                    <input type="text" className="form-control" onKeyDown={inputSoloNumeros} {...register('CodNotario', {
                                                    })} />
                                                    {errors.CodNotatio && <span className="text-danger">{errors.CodNotatio.message}</span>}
                                                </div>
                                                <div className="col-5 mt-2">
                                                    <label>Codigo Notario 2</label>
                                                </div>
                                                <div className="col-7 mt-2">
                                                    <input type="text" className="form-control" style={{ marginBottom: "15px" }} onKeyDown={inputSoloNumeros} {...register('CodNotario2', {
                                                    })} />
                                                    {errors.CodNotatio && <span className="text-danger">{errors.CodNotatio.message}</span>}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="submit" className="btn btn-primary">Guardar cambios</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
