import { api, apiPV	 } from "./configApi"

export const tramiteIofe = async (dataPOST) => {
    try {
        const res = await api.post("api/iofe/inmatriculacion", dataPOST)
        
        if (res.status === 200) {
            return {
                status: 1,
                message: "Se envió a IOFE",
            }
        }

    } catch (error) {
        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al enviar a IOFE",
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const tramitePruebaVida = async (dataPOST,headers) => {
    try {
        const res = await api.post("api/iofe/pruebaVidaLink", dataPOST,{ headers })        
        if (res.status === 200) {
            return {
                status: 1,
                message: "",
                data: res.data
            }
        }

    } catch (error) {        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al enviar a IOFE Prueba de Vida",
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}


export const updatePruebaVida = async (dataPOST,headers) => {
    try {
        const res = await api.post("api/iofe/pruebavidaUpdate", dataPOST,{ headers })        
        if (res.status === 200) {
            return {
                status: 1,
                message: "",
                data: res.data
            }
        }

    } catch (error) {        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al actualizar a IOFE Prueba de Vida",
                data: false
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
            data: false
        };
    }
}


export const createPruebaVida = async (dataPOST,headers) => {
    try {
        const res = await api.post("api/iofe/pruebavidaCreate", dataPOST,{ headers })        
        if (res.status === 200) {
            return {
                status: 1,
                message: "",
                data: res.data
            }
        }

    } catch (error) {        
        console.log("error " , error );
        
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error al enviar a IOFE Prueba de Vida",
                data: false
            };
        }
        return {
            status: -1,
            message: "Error en el servidor",
            data: false
        };
    }
}




 //  listado de companias



export const getListadoCompanias = async (token) => {
    try {
        const res = await api.post("api/common/companiaListar", {}, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: []
        }
    }
}


export const getListarRepresentantesXSucursal = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/representantesSucursal", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: []
        }
    }
}


export const listarConfiguracionRepresentanteIofe = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/iofeRepresentanteFlujoListar", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: []
        }
    }
}


export const registrarConfiguracionRepresentanteIofe = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/iofeRepresentanteFlujo", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",

        }
    }
}

export const getListadoRepresentantesSinSucursal = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/representantesSinSucursal", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }
    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: []
        }
    }
}


// notarios

export const getListadoCompaniasNotario = async (token) => {
    try {
        const res = await api.post("api/common/ListarCompanias", {}, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }


    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: [{
                "value": "12345",
                "description": "Código de producto"
            },
            {
                "value": "ABC123",
                "description": "Código de promoción"
            },
            {
                "value": "2023-10-02",
                "description": "Fecha de solicitud"
            }]
        }
    }
}


export const getListarNotariosXCompania = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/notariosXCompania", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }

    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: [{
                "id": "001",
                "doctipo": "DNI",
                "docnumero": "12345678",
                "nombre": "Juan Pérez",
                "desde": "01-01-2025",
                "hasta": "31-12-2023",
                "TipNotario": "P"
            },
            {
                "id": "002",
                "doctipo": "Pasaporte",
                "docnumero": "A12345678",
                "nombre": "María López",
                "desde": "15-05-2022",
                "hasta": "14-05-2025",
                "TipNotario": "L"
            }]
        }
    }
}


export const listarConfiguracionNotario = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/notarioFlujoListar", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }

    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: [{
                "compania": "Compania ABC",
                "notario": "D46423011",
                "tipo_notario": "P",
                "fecha_desde": "2023-01-15",
                "fecha_hasta": "2024-01-15",
                "estado": "Activo",
                "notario_r": "D701546651",
                "codigos_notarios": [
                    {
                        "id": "codigo_notario_1",
                        "value": "ABC123"
                    },
                    {
                        "id": "codigo_notario_2",
                        "value": "ABC265"
                    }
                ]
            },
            // {
            //     "compania": "Compania XYZ",
            //     "notario": "D56423012",
            //     "tipo_notario": "L",
            //     "fecha_desde": "2022-05-01",
            //     "fecha_hasta": "2023-05-01",
            //     "estado": "Inactivo",
            //     "notario_r": "D953112144",
            //     "codigos_notarios": [
            //         {
            //             "id": "codigo_notario_2",
            //             "value": "XYZ456"
            //         }
            //     ]
            // },
            // {
            //     "compania": "Compania DEF",
            //     "notario": "D66423013",
            //     "tipo_notario": "P",
            //     "fecha_desde": "2023-07-20",
            //     "fecha_hasta": "2024-07-20",
            //     "estado": "Activo",
            //     "notario_r": "D021548746",
            //     "codigos_notarios": [
            //         {
            //             "id": "codigo_notario_3",
            //             "value": "DEF789"
            //         }
            //     ]
            // }
            ]
        }
    }
}


export const registrarConfiguracionNotario = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/notarioFlujo", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",

        }
    }
}

export const getListadoNotariosSinConfiguracion = async (dataPost, token) => {
    try {
        const res = await api.post("api/iofe/notariosSinConfiguracion", dataPost, {
            headers: {
                token: token
            }
        });
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: res.data.data
            }
        }
        if (res.data.status === 0) {
            return {
                status: res.data.status,
                message: res.data.message,
                data: []
            }
        }

    } catch (error) {
        return {
            status: -1,
            message: "Error en el servidor",
            data: [{
                "notario": "NT001",
                "nombre": "Juan Pérez",
                "tipo_notario": "P",
                "registrado": "S"
            },
            {
                "notario": "NT002",
                "nombre": "María López",
                "tipo_notario": "L",
                "registrado": "N"
            },
            {
                "notario": "NT003",
                "nombre": "Carlos García",
                "tipo_notario": "P",
                "registrado": "S"
            },
            {
                "notario": "NT002",
                "nombre": "María López",
                "tipo_notario": "L",
                "registrado": "S"
            },
            {
                "notario": "NT002",
                "nombre": "Jose López",
                "tipo_notario": "L",
                "registrado": "N"
            },
            {
                "notario": "NT002",
                "nombre": "Luis López",
                "tipo_notario": "L",
                "registrado": "S"
            },
            {
                "notario": "NT002",
                "nombre": "Leonardo Santos",
                "tipo_notario": "L",
                "registrado": "N"
            },
            ]
        }
    }
}