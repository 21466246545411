import React, { useContext, useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { TramitePersona } from "./TramitePersona";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ruta_historial,
  ruta_tramite_confirmacion,
  ruta_tramite_pago_agregar,
  ruta_tramite_pago_editar,
} from "../../constants/constants-rutas-codigo";
import { DataContext } from "../../context/DataContext";
import {actualizarTramite, tramiteCrear } from "../../api/tramiteApi";
import { Alert, Grid } from "@mui/material";
import {
  inputNumber,
  convertBase64,
  guardarLocalStorage,
  limpiarLocalStorage,
  obtenerLocalStorage,
} from "../../resources/funciones";
import Swal from 'sweetalert2';


const Tramite = ( ) => {
  const navigate = useNavigate();
  const location = useLocation();
  const accion = location.pathname.split("/").pop(); 
  const datosTramite = location.state
  const estadoTramite = datosTramite?.estadoTramite
  const token = obtenerLocalStorage("usuario")?.token;
  const {
    listaTipoInmatriculacion,
    listaPuertos,
    listaRegimenes,
    listaRepLegal,
    getDataRepresLegal,
  } = useContext(DataContext);

  const datosTramiteLS = obtenerLocalStorage("tramite");
  let fecha = new Date();
  fecha.setDate(fecha.getDate());
  let anioActual = fecha.toISOString().substring(0, 4);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      TipoInmatriculacion:
        datosTramiteLS?.dataCompradores?.TipoInmatriculacion ||
        datosTramite?.datos_tramite[0].TramitTipo ||
        "N",
      RepLegal:
        datosTramiteLS?.dataCompradores?.RepLegal ||
        datosTramite?.datos_tramite[0].Representante_Legal[0].PerId ||
        "",
      Compradores: datosTramiteLS?.dataCompradores?.Compradores ||
        datosTramite?.datos_compradores  || [
          { TipoDoc: "D", EstCivil: "S" },
        ],
      Puerto:
        datosTramiteLS?.dataCompradores?.DUA.slice(0, 3) ||
        datosTramite?.datos_tramite[0].TramitDua.slice(0, 3) ||
        "",
      Anio:
        datosTramiteLS?.dataCompradores?.DUA.slice(3, 7) ||
        datosTramite?.datos_tramite[0].TramitDua.slice(3, 7) ||
        "",
      Regimen:
        datosTramiteLS?.dataCompradores?.DUA.slice(7, 9) ||
        datosTramite?.datos_tramite[0].TramitDua.slice(7, 9) ||
        "10",
      NumDUA:
        datosTramiteLS?.dataCompradores?.DUA.slice(9, 15) ||
        datosTramite?.datos_tramite[0].TramitDua.slice(9, 15) ||
        "",
      Item:
        datosTramiteLS?.dataCompradores?.DUA.slice(15) ||
        datosTramite?.datos_tramite[0].TramitDua.slice(15) ||
        "",
  
    },
  });

  const [mostrarError, setMostrarError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [mostrarSpinner, setMostrarSpinner] = useState(false);
  const {
    fields: compradoresFields,
    append: compradoresAppend,
    remove: compradoresRemove,
  } = useFieldArray({ control, name: "Compradores" });
  
  const onSubmitEditar = (data) => { };

  const irPagos = async () => {
    setValue(
      "DUA",
      watch("Puerto") +
      watch("Anio") +
      watch("Regimen") +
      watch("NumDUA") +
      watch("Item")
    );
    
    const listaCompradores = await Promise.all(
      watch("Compradores").map(async (d) => {
        if (watch("TipoInmatriculacion") !== "N") {
          return {
            ...d,
            RepImagenFrontal:
              (await convertBase64(d?.RepImagenFrontal?.[0])) || "",
            RepImagenDorsal:
              (await convertBase64(d?.RepImagenDorsal?.[0])) || "",
            RepImagenCliente:
              (await convertBase64(d?.RepImagenCliente?.[0])) || "",
            RepVigPod: (await convertBase64(d?.RepVigPod?.[0])) || "",
            RepFichaRuc: (await convertBase64(d?.RepFichaRuc?.[0])) || "",
          };
        }
        const nuevoObjeto = {
          ...d,
          pdf: (await convertBase64(d?.pdf?.[0])) || "",
          ImagenFrontal: (await convertBase64(d?.ImagenFrontal?.[0])) || "",
          ImagenDorsal: (await convertBase64(d?.ImagenDorsal?.[0])) || "",
          ImagenCliente: (await convertBase64(d?.ImagenCliente?.[0])) || "",
          ConyImagenFrontal:
            (await convertBase64(d?.ConyImagenFrontal?.[0])) || "",
          ConyImagenDorsal:
            (await convertBase64(d?.ConyImagenDorsal?.[0])) || "",
          ConyImagenCliente:
            (await convertBase64(d?.ConyImagenCliente?.[0])) || "",
        };
        return nuevoObjeto;
      })
    );
    
    
    const dataCompradores = {
      ...watch(),
      Compradores: listaCompradores,
    };

    const dataMedioPago = datosTramite?.datos_tramite[0].Medio_Pago.map(
      (m) => ({
        Comprobante: m.PagBolFac,
        Serie: m.PagSerie,
        Numero: m.PagNum,
        Moneda: m.PagoTipMon,
        Importe: parseFloat(m.PagoImpor),
        Fecha: m.PagoFchBol,
      })
    );
    const dataCancelacion = datosTramite?.datos_tramite[0].Cancelacion.map(
      (c) => ({
        Codigo: c.CancelCod,
        Moneda: c.CancelTipMon,
        TipoCambio: parseFloat(c.CancTipCamb),
        Importe: parseFloat(c.CancelMon),
        Fecha: c.CancelFch,
        Operacion: c.CancelOp,
        Banco: c.CancelBanc,
        MonCancelacion: isNaN(parseFloat(c.CancelMonTot)) ? 0 : parseFloat(c.CancelMonTot)
      })
    );
    const TipoPago = datosTramite?.datos_tramite[0]?.TipoPago
    const ImporteCredito = datosTramite?.datos_tramite[0]?.ImporteCredito
    const dataVehiculo = datosTramite?.datos_tramite[0].Vehiculo || {};
    const idTramite = obtenerLocalStorage("tramite")?.idTramite;
    const nota =
      obtenerLocalStorage("tramite")?.datos_tramite?.[0]?.TramitObs;
    guardarLocalStorage(
      {
        dataCompradores,
        dataMedioPago,
        dataCancelacion,
        dataVehiculo,
        idTramite,
        nota,
        accion,
        TipoPago,
        ImporteCredito
      },
      "tramite"
    )
    if (accion === "agregar") {
      navigate(ruta_tramite_pago_agregar, {
        state: {
          dataCompradores,
          dataMedioPago,
          dataCancelacion,
          dataVehiculo,
          nota,
          accion,
          TipoPago,
          ImporteCredito
        },
      });
    } else {
      navigate(ruta_tramite_pago_editar, {
        state: {
          dataCompradores,
          dataMedioPago,
          dataCancelacion,
          dataVehiculo,
          nota,
          accion,
          estadoTramite,
          TipoPago,
          ImporteCredito
        },
      });
    }  
  };


  const actualizarPersona = async (data) => {
    setValue(
      "DUA",
      watch("Puerto") +
      watch("Anio") +
      watch("Regimen") +
      watch("NumDUA") +
      watch("Item")
    );
    const idTramite = obtenerLocalStorage("tramite")?.idTramite;
    const listaCompradores = await Promise.all(
      watch("Compradores").map(async (d) => {
        if (watch("TipoInmatriculacion") !== "N") {
          return {
            ...d,
            RepImagenFrontal:
              (await convertBase64(d?.RepImagenFrontal?.[0])) || "",
            RepImagenDorsal:
              (await convertBase64(d?.RepImagenDorsal?.[0])) || "",
            RepImagenCliente:
              (await convertBase64(d?.RepImagenCliente?.[0])) || "",
            RepVigPod: (await convertBase64(d?.RepVigPod?.[0])) || "",
            RepFichaRuc: (await convertBase64(d?.RepFichaRuc?.[0])) || "",
            Ubigeo: d.Ubigeo.TUbiCod? d.Ubigeo.TUbiCod : d.Ubigeo,
           
          };
        }
 

        const nuevoObjeto = {
          ...d,
          pdf: (await convertBase64(d?.pdf?.[0])) || "",
          ImagenFrontal: (await convertBase64(d?.ImagenFrontal?.[0])) || "",
          ImagenDorsal: (await convertBase64(d?.ImagenDorsal?.[0])) || "",
          ImagenCliente: (await convertBase64(d?.ImagenCliente?.[0])) || "",
          ConyImagenFrontal:
            (await convertBase64(d?.ConyImagenFrontal?.[0])) || "",
          ConyImagenDorsal:
            (await convertBase64(d?.ConyImagenDorsal?.[0])) || "",
          ConyImagenCliente:
            (await convertBase64(d?.ConyImagenCliente?.[0])) || "", 
            Ubigeo: d.Ubigeo.TUbiCod? d.Ubigeo.TUbiCod : d.Ubigeo,
            ConyUbigeo: (d.ConyUbigeo.TUbiCod? d.ConyUbigeo.TUbiCod : "" ) || "" 
        };
        return nuevoObjeto;
      })
    );
    const dataCompradores = {
      ...watch(),
      Compradores: listaCompradores,
      Inmatriculacion: idTramite
    };

 


    const token = obtenerLocalStorage("usuario").token;
    const { status, message } = await actualizarTramite(dataCompradores);
    if (status == 1) {
      Swal.fire({
        icon: 'success',
        title: message,
        html: `
        <p>
          - Si cambio nombres o direcciones se tendra que volver a generar los documentos.
        
        </p>
        `
      });
    } else if (status == 0) {
      Swal.fire({
        icon: 'error',
        title: message,
        text: 'Hubo un problema con la solicitud, intentelo de nuevo mas tarde.'
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: message,
        text: 'Hubo un error al actualizar, contactese con sistemas.'
      });
    }
  }
 
  const registrarPersona = async () => {
    const dua =
      watch("Puerto") +
      watch("Anio") +
      watch("Regimen") +
      watch("NumDUA") +
      watch("Item");
    setValue("DUA", dua);
    
    let listaPer = [];
    const medioPago = [];
    const cancelacion = [];
    let comprador = await Promise.all(
      watch("Compradores").map(async (d) => {
        if (watch("TipoInmatriculacion") === "N") {
          let pdf = (await convertBase64(d?.pdf?.[0])) || "";
          let imagenFrontal =
            (await convertBase64(d?.ImagenFrontal?.[0])) || "";
          let imagenDorsal =
            (await convertBase64(d?.ImagenDorsal?.[0])) || "";
        
          let conyImagenFrontal =
            (await convertBase64(d?.ConyImagenFrontal?.[0])) || "";
          let conyImagenDorsal =
            (await convertBase64(d?.ConyImagenDorsal?.[0])) || "";
      
          if (d.UnionHecho === "S" || d.SocCony === "S") {
            listaPer.push({
              NomPer: d.ConyNombre.trim(),
              ApellPatPer: d.ConyApellidoP.trim(),
              ApellMatPer: d.ConyApellidoM.trim(),
              RazSocPer: "",
              TipDocPer: d.ConyTipoDoc,
              NumDocPer: d.ConyNumDoc,
              TipContra: "C",
              RucEmpre: "",
              TelefonoPer: d.ConyTelefono,
              EmailPer: d?.ConyEmail||"",
              EstCivPer: d.ConyEstCivil,
              UnionHechPer: d.UnionHecho,
              IdConyugPer: d.TipoDoc + d.NumDoc,
              SocConyugPer: d.SocCony,
              SeparPatrPer: d.SepPatr,
              PartRegPer: d?.PartReg|| "",
              OficRegPer: d.OficReg || "",
              DocConyugPer: pdf?.archivoBase64 || "",
              DniFrontalPer: {
                b64: conyImagenFrontal?.archivoBase64 || "",
                ext: conyImagenFrontal?.nombreArchivo?.split(".").pop() || "",
              },
              DniDorsalPer: {
                b64: conyImagenDorsal?.archivoBase64 || "",
                ext: conyImagenDorsal?.nombreArchivo?.split(".").pop() || "",
              },
              VigenciaPoder: "",
              FichaRuc: "",
              RolPer: "C",
              EstadoPer: "A",
              Direccion: {
                DomiDir: d.ConyDomicilio.trim(),
                UbigeoDir: d.ConyUbigeo.TUbiCod.trim(),
              },
            });
          }
          return {
            NomPer: d.Nombre.trim(),
            ApellPatPer: d.ApellidoP.trim(),
            ApellMatPer: d.ApellidoM.trim(),
            TipDocPer: d.TipoDoc,
            NumDocPer: d.NumDoc,
            TipContra: "C",
            RucEmpre: "",
            TelefonoPer: d.Telefono,
            EmailPer: d.Email.trim(),
            EstCivPer: d.EstCivil,
            UnionHechPer: d.UnionHecho,
            IdConyugPer:
              d.UnionHecho === "S" || d.SocCony === "S"
                ? d?.ConyTipoDoc + d?.ConyNumDoc
                : "",
            SocConyugPer: d.SocCony,
            SeparPatrPer: d.SepPatr,
            PartRegPer:
              d.UnionHecho === "S" || d.SepPatr === "S" ? d.PartReg.trim() : "",
            OficRegPer:
              d.UnionHecho === "S" || d.SepPatr === "S" ? d.OficReg : "",
            DocConyugPer:
              d.UnionHecho === "S" || d.SepPatr === "S"
                ? pdf?.archivoBase64
                : "",
            DniFrontalPer: {
              b64: imagenFrontal?.archivoBase64 || "",
              ext: imagenFrontal?.nombreArchivo?.split(".").pop() || "",
            },
            DniDorsalPer: {
              b64: imagenDorsal?.archivoBase64 || "",
              ext: imagenDorsal?.nombreArchivo?.split(".").pop() || "",
            },
            VigenciaPoder: "",
            FichaRuc: "",
            RolPer: "C",
            EstadoPer: "A",
            Direccion: {
              DomiDir: d.Domicilio.trim(),
              UbigeoDir: d.Ubigeo.TUbiCod,
            },
          };
        } else {
          let repImagenFrontal =
            (await convertBase64(d?.RepImagenFrontal?.[0])) || "";
          let repImagenDorsal =
            (await convertBase64(d?.RepImagenDorsal?.[0])) || "";

          let repVigPod = (await convertBase64(d?.RepVigPod?.[0])) || "";
          let repFichaRuc = (await convertBase64(d?.RepFichaRuc?.[0])) || "";
          if (watch("TipoInmatriculacion") === "J") {
            listaPer.push({
              NomPer: d.RepNombre.trim(),
              ApellPatPer: d.RepApellidoP.trim(),
              ApellMatPer: d.RepApellidoM.trim(),
              RazSocPer: "",
              TipDocPer: d.RepTipoDoc,
              NumDocPer: d.RepNumDoc,
              TipContra: "R",
              RucEmpre: d.NumDoc,
              TelefonoPer: d.RepTelefono,
              EmailPer: d.RepEmail.trim(),
              EstCivPer: "",
              UnionHechPer: "N",
              IdConyugPer: "",
              SocConyugPer: "N",
              SeparPatrPer: "N",
              PartRegPer: "",
              OficRegPer: "",
              DocConyugPer: "",
              DniFrontalPer: {
                b64: repImagenFrontal?.archivoBase64 || "",
                ext: repImagenFrontal?.nombreArchivo?.split(".").pop() || "",
              },
              DniDorsalPer: {
                b64: repImagenDorsal?.archivoBase64 || "",
                ext: repImagenDorsal?.nombreArchivo?.split(".").pop() || "",
              },
              VigenciaPoder: repVigPod?.archivoBase64 || "",
              FichaRuc: repFichaRuc?.archivoBase64 || "",
              RolPer: "C",
              EstadoPer: "A",
              Direccion: {
                DomiDir: "",
                UbigeoDir: "",
              },
            });
          }
          return {
            NomPer: "",
            ApellPatPer: "",
            ApellMatPer: "",
            TipDocPer: d.TipoDoc,
            NumDocPer: d.NumDoc,
            RazSocPer: d.RazonSocial.trim(),
            TipContra: "C",
            RucEmpre: "",
            TelefonoPer: "",
            EmailPer: "",
            EstCivPer: "",
            UnionHechPer: "",
            IdConyugPer: "",
            SocConyugPer: "",
            SeparPatrPer: "",
            PartRegPer: d.PartReg.trim(),
            OficRegPer: d.OficReg,
            DocConyugPer: "",
            DniFrontalPer: {
              b64: "",
              ext: "",
            },
            DniDorsalPer: {
              b64: "",
              ext: "",
            },
            VigenciaPoder: "",
            FichaRuc: "",
            RolPer: "C",
            EstadoPer: "A",
            Direccion: {
              DomiDir: d.Domicilio.trim(),
              UbigeoDir: d.Ubigeo.TUbiCod.trim(),
            },
          };
        }
      })
    );
    let personas = comprador.concat(listaPer);
    personas.forEach(p => {
      p.NumDocPer = p.NumDocPer.trim()
      p.IdConyugPer = p.IdConyugPer.trim()
    })
    const dataPOST = {
      TipoTramit: watch("TipoInmatriculacion"),
      EstTramit: "01",
      DuaTramit: watch("DUA").length === 9 ? "" : watch("DUA"),
      tokenCompañia: token,
      RepresLegalTramit: watch("RepLegal"),
      Medio_Pago: medioPago,
      Cancelacion: cancelacion,
      ObsTramit: "",
      Personas: personas,
      XmlPag: "",
      CodIofeTramit: "",
    };
    setMostrarSpinner(true);
    const { status, message, num_tramite } = await tramiteCrear(dataPOST);
    if (status === 1) {      
      navigate(ruta_tramite_confirmacion, { state: { num_tramite } });
    } else {
      setMostrarSpinner(false);
      setMostrarError(true);
      setMensaje(message)
    }
  };

  const irBandeja = () => {
    if (accion === "editar") {
      limpiarLocalStorage("tramite");
      limpiarLocalStorage("pagos");
    }
    navigate(ruta_historial);
  };

  useEffect(() => {
    if (token) {
      getDataRepresLegal(token);
    }
    
  }, []);
  useEffect(() => {
    compradoresFields.forEach((c) => {
      if (watch("TipoInmatriculacion") !== "N") {
        c.TipoDoc = "R";
      } else {
        c.TipoDoc = "D";
      }
    });
    
  }, [watch("TipoInmatriculacion")]);
  const isButtonDisabled = accion === "editar";
  return (
    <div>
      <div className="container pt-2">
        <form
          className="g-3"
          onSubmit={(e) => {
            e.preventDefault();
            if (accion === "agregar") {
              handleSubmit(irPagos);
              handleSubmit(registrarPersona);
            } else {
              handleSubmit(onSubmitEditar);
            }
          }}
        >
          <div className="pt-3">
            <div className="row form-seccion">
              <h5 className="col-lg-2 col-md-4 col-sm-12 pt-2">
                Inmatriculación
              </h5>
              <div className="col-lg-4 col-md-8 col-sm-12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  defaultValue="N"
                  {...register("TipoInmatriculacion")}
                  disabled={isButtonDisabled}
                >
                  {listaTipoInmatriculacion.map((tipInm, key) => {
                    if (tipInm.TipInmEst === "S") {
                      return (
                        <option
                          key={`tipInm_op_${key}`}
                          value={tipInm.TipInmCod}
                        
                        >
                          {tipInm.TipInmDes}
                        </option>
                      )
                    } else {
                      return null
                    }
                  })}
                </select>
              </div>
              <h5 className="col-lg-2 col-md-4 col-sm-12 pt-2">
                Representantes
              </h5>
              <div className="col-lg-4 col-md-8 col-sm-12">
                <select
                  className="form-select"
                  aria-label="Default select example"                  
                  {...register("RepLegal", { required: true })}
                  value={watch("RepLegal")}
                >
                  <option value="">---Seleccione---</option>
                  {listaRepLegal?.map((repLeg, key) => {
                    return (
                      <option key={`rep_op_${key}`} value={repLeg.PerId}>{repLeg.PerNomCom}</option>
                    );
                  })}
                </select>
                {errors.RepLegal && (
                  <span className="error">
                    Por favor, eliga un representante legal
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="pb-3">
            <h5 className="pt-4">Datos de Importación</h5>
            <div className="row form-section">
              <div className="col-md-12">                
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                    <label className="form-label">Puerto/Dependencia</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      {...register("Puerto", { required: true })}
                    >
                      <option value="">---Seleccione---</option>
                      {listaPuertos.map((p, index) => {
                        return (
                          <option
                            key={`puerto_op_${index}`}
                            value={p.codigo}
              
                          >
                            {p.puerto}
                          </option>
                        );
                      })}
                    </select>
                    {errors.Puerto && (
                      <span className="error">
                        Requerido
                      </span>
                    )}
                  </div>
                  <div className="col-xl-1 col-lg-2 col-md-4 col-sm-12">
                    <label className="form-label">Año</label>
                    <input
                      type="number"
                      className="form-control"
                      maxLength={4}
                      min='0'
                      defaultValue={anioActual}
                      onKeyDown={inputNumber}
                      {...register("Anio", { required: true })}
                    />
                    {errors.Anio && (
                      <span className="error">
                        Requerido
                      </span>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                    <label className="form-label">Régimen</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue="10"
                      {...register("Regimen", { required: true })}
                    >                      
                      {listaRegimenes.map((r, index) => {
                        return (
                          <option
                            key={`regimen_op_${index}`}
                            value={r.codigo}
                          
                          >
                            {r.regimen}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                    <label className="form-label">DUA</label>
                    <input
                      type="text"
                      className="form-control"
                      min='0'
                      onKeyDown={(e) => inputNumber(e, false, 6)}
                      {...register("NumDUA",
                        {
                          required: accion === "editar" ? true : false,
                          maxLength: 6,
                          validate: (value) => {
                            if (value.length !== 6) {
                              return "Debe tener exactamente 6 dígitos"
                            }
                          }
                        }
                      )}
                    />
                    {errors.NumDUA && errors.NumDUA.type === 'required' && (
                      <span className="error">
                        Requerido
                      </span>
                    )}
                    {
                      errors.NumDUA && errors.NumDUA.type === 'maxLength' && (
                        <span className="error">
                          Debe tener de 6 dígitos
                        </span>
                      )}
                    {
                      errors.NumDUA && (
                        <span className="error">
                          {errors.NumDUA.message}
                        </span>
                      )
                    }
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-2 col-sm-12">
                    <label className="form-label">Item</label>
                    <input
                      type="number"
                      className="form-control"
                      min='0'
                      onKeyDown={inputNumber}
                      {...register("Item",
                        {
                          required: accion === "editar" ? true : false,
                          validate: (value) => (value.length <= 3)
                        }
                      )}
                    />
                    {errors.Item && errors.Item.type === 'required' && (
                      <span className="error">Requerido</span>
                    )}
                    {errors.Item && errors.Item.type === 'validate' && (
                      <span className="error" style={{ position: 'relative' }}>El item no debe superar los 3 dígitos</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {compradoresFields.map((item, index) => {
            
            return (
              <div key={index}>
                <TramitePersona
                  index={index}
                  register={register}
                  errors={errors}
                  reset={reset}
                  watch={watch}
                  control={control}
                 
                  setValue={setValue}
                  compradoresRemove={compradoresRemove}
                  accion={accion}
                />
              </div>
            );
          })}
          {mostrarError && (
            <div className="row mt-3">
              <div className="col-md-4">
                <Grid item xs={6}>
                  <Alert variant="outlined" severity="warning">
                    {mensaje}
                  </Alert>
                </Grid>
              </div>
            </div>
          )}
          <div className="col-12 mt-3">
            <button
              className="btn btn-main"
              onClick={(e) => {
                e.preventDefault();
                compradoresAppend();
              }}
            >
              Agregar Comprador
            </button>
          </div>
          <div className="col-12 mt-3 py-3 text-center">
            {mostrarSpinner ? (
              <div className="spinner-border"></div>
            ) : (
              <>
                <button className="btn btn-secundario mx-3  ps-5 pe-5 mb-2" onClick={irBandeja}>
                  Cancelar
                </button>
                {accion === "agregar" && (
                  <button
                    className="btn btn-main mx-3 ps-5 pe-5 mb-2"
                    
                    onClick={handleSubmit(registrarPersona)}
                  >
                    Registrar
                  </button>
                )}
                {accion === "editar" && (
                  <>
                    <button
                      className="btn btn-main mx-3 ps-5 pe-5 mb-2"
                      type="submit"
                      onClick={handleSubmit(irPagos)}
                    >
                      Continuar
                    </button>
                    <button className="btn btn-main mx-3 ps-5 pe-5 mb-2"
                      type="submit"
                      onClick={handleSubmit(actualizarPersona)}
                    >
                      Actualizar
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default Tramite;
