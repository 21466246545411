import { useEffect, useState } from "react"
import React from "react"

export const FileFormats = {
    PDF: "application/pdf",
    XML: "text/xml",
    IMAGE: "image/jpg, image/jpeg, image/png"
}

export const XmlInput = ({
    id,
    register,
    refText,
    fileName,
    format,
    formatError,
    min,
    minError,
    max,
    maxError,
    xmlData,
    isArchiveSelected,
    enviarDatos
}) => {

    const emptyMessage = 'Ningún archivo seleccionado.'
    const [nombreArchivo, setNombreArchivo] = useState(emptyMessage)
    const [isArchiveSelected2, setIsArchiveSelected2] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);


    useEffect(() => {
        const restoreFile = () => {
            if (fileName) {
                setNombreArchivo(fileName)
            }
        }
        restoreFile()
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            if (
                isArchiveSelected &&
                xmlData &&
                xmlData.Invoice &&
                xmlData.Invoice['cbc:ID'] &&
                xmlData.Invoice['cac:LegalMonetaryTotal'] &&
                xmlData.Invoice['cac:LegalMonetaryTotal']['cbc:PayableAmount'] &&
                xmlData.Invoice['cac:AccountingCustomerParty']['cac:Party']['cac:PartyLegalEntity']['cbc:RegistrationName'] &&
                xmlData.Invoice['cbc:IssueDate']
            ) {
                const invoiceType = xmlData.Invoice['cbc:ID']._text[0] === 'B' ? 'Boleta' : 'Factura';
                const invoiceId = xmlData.Invoice['cbc:ID']._text;
                const payableAmount = xmlData.Invoice['cac:LegalMonetaryTotal']['cbc:PayableAmount']._text;
                const currencyID = xmlData.Invoice['cac:LegalMonetaryTotal']['cbc:PayableAmount']._attributes.currencyID;
                const clientName = xmlData.Invoice['cac:AccountingCustomerParty']['cac:Party']['cac:PartyLegalEntity']['cbc:RegistrationName']._cdata;
                const fecha = xmlData.Invoice['cbc:IssueDate']._text;

                const invoiceData = {
                    type: invoiceType,
                    id: invoiceId,
                    amount: payableAmount,
                    currency: currencyID === 'USD' ? 'Dólares' : 'Soles',
                    clientName: clientName,
                    fecha: fecha
                };

                setInvoiceData(invoiceData);
                setIsArchiveSelected2(true);
                await enviarDatos(invoiceData);
            } else if (isArchiveSelected) {
                setInvoiceData(null);
                setIsArchiveSelected2(true);
            } else if (!isArchiveSelected) {
                setIsArchiveSelected2(false);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArchiveSelected2, isArchiveSelected, xmlData]);



    const limitNameLength = (nombreArchivo) => {

        const nombre = nombreArchivo
        if (nombre.length > 30) {
            const tailChars = nombre.slice(-7)
            const visible = nombre.slice(0, 20)
            return visible + "..." + tailChars
        } else {
            return nombre
        }
    }


    const onChangeHandler = (e) => {
        if (e.target.value === "") {
            if (!fileName) {
                setNombreArchivo(emptyMessage)
            } else {
                setNombreArchivo(fileName)
            }
        } else {
            const value = e.target.value.replace('C:\\fakepath\\', "")
            setNombreArchivo(value)
        }
    }

    const requestValidation = (value) => {
        if (fileName || value[0]) {
            return true
        } else {
            return false || "El archivo es requerido"
        }
    }

    const typeValidation = (value) => {
        if (value[0]) {
            if (format) {
                if (value[0]?.type === format || format.includes(value[0]?.type)) {
                    return true
                } else {
                    return false || formatError
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const minValidation = (value) => {
        if (value[0]) {
            if (!min) {
                return true
            } else {
                return (value[0]?.size > min) || minError
            }
        } else {
            return true
        }
    }

    const maxValidation = (value) => {
        if (value[0]) {
            if (!max) {
                return true
            } else {
                return (value[0]?.size < max) || maxError
            }
        } else {
            return true
        }
    }


    const xmlValidation = (value) => {
        if (value[0]) {
            if (invoiceData) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }


    return (
        <>
            <label className="file-group" htmlFor={id}>
                <div className="file file-btn">Elegir archivo</div>
                <div className="file file-text">{limitNameLength(nombreArchivo)}</div>
            </label>
            <input
                {...register(refText, {
                    validate: {
                        required: value => requestValidation(value),
                        type: value => typeValidation(value),
                        maximum: value => maxValidation(value),
                        minimum: value => minValidation(value),
                        externalValidation: value => xmlValidation(value),
                    },
                    onChange: onChangeHandler
                })}
                id={id}
                className="d-none"
                type="file"
                accept={format}
            />

            {isArchiveSelected2 && (
                isArchiveSelected && xmlData && xmlData.Invoice && xmlData.Invoice['cbc:ID'] && xmlData.Invoice['cac:LegalMonetaryTotal'] && xmlData.Invoice['cac:LegalMonetaryTotal']['cbc:PayableAmount'] ? (
                    <span style={{ fontSize: "0.9rem" }}>
                        <b>
                            {`${xmlData.Invoice['cbc:ID']._text[0] === 'B' ? 'Boleta' : 'Factura'}: ${xmlData.Invoice['cbc:ID']._text} - Cliente: ${xmlData.Invoice['cac:AccountingCustomerParty']['cac:Party']['cac:PartyLegalEntity']['cbc:RegistrationName']._cdata}`.toUpperCase()}
                            <br />
                        </b>
                    </span>

                ) : (
                    <span className="error">El archivo adjunto no es un comprobante de pago en formato xml</span>
                )
            )}

        </>
    )
}