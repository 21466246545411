import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  agregarDireccion,
  direccionEditar,
  personaCrear,
  personaEditar,
} from "../../api/personaApi";
import { ruta_personas } from "../../constants/constants-rutas-codigo";
import { DataContext } from "../../context/DataContext";
import {
  limpiarLocalStorage,
  obtenerLabel,
  obtenerLocalStorage,
} from "../../resources/funciones";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { personaBuscar } from "../../api/tramiteApi";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const Persona = () => {
  const location = useLocation();
  const accion = location.pathname.split("/").pop(); // agregar  o editar

  const [datosPersonaLS, setDatosPersonaLS] = useState(location.state);

  // const datosPersonaLS = obtenerLocalStorage("persona");
  const estilosCompania = obtenerLocalStorage("usuario")?.estilo;

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      TipoPer: datosPersonaLS?.persona?.TipoPer || "",
      TipoDoc: datosPersonaLS?.persona?.TipoDoc || "D",
      NumDoc: datosPersonaLS?.persona?.NumDoc || "",
      ApellidoP: datosPersonaLS?.persona?.ApellidoP || "",
      ApellidoM: datosPersonaLS?.persona?.ApellidoM || "",
      Nombre: datosPersonaLS?.persona?.Nombre || "",
      RazonSocial: datosPersonaLS?.persona?.RazonSocial || "",
      Email: datosPersonaLS?.persona?.Email || "",
      Telefono: datosPersonaLS?.persona?.Telefono || "",
      EstCivil: datosPersonaLS?.persona?.EstCivil || "S",
      UnionHecho: datosPersonaLS?.persona?.UnionHecho || "N",
      SepPatr: datosPersonaLS?.persona?.SepPatr || "N",
      SocCony: datosPersonaLS?.persona?.SocCony || "N",
      PartReg: datosPersonaLS?.persona?.PartReg || "",
      OficReg: datosPersonaLS?.persona?.OficReg || "",

      Direcciones: datosPersonaLS?.persona?.Direcciones || [],

      // pdf: "",
      // ConyTipoDoc: "",
      // ConyNumDoc: "",
      // ConyApellidoP: "",
      // ConyApellidoM: "",
      // ConyNombre: "",
      // ConyUbigeo: "",
      // ConyDomicilio: "",
      // ConyEstCivil: ""
    },
  });

  const {
    listaUbigeo,
    listaTipoDocumentos,
    listaTipoEstadoCivil,
    listaEstado,
    listaOficinasReg,
    getDataRepresLegal,
  } = useContext(DataContext);

  const {
    fields: direccionesFields,
    append: direccionesAppend,
    remove: direccionesRemove,
  } = useFieldArray({ control, name: "Direccciones" });

  const {
    fields: direccionesDirConyFields,
    append: direccionesDirConyAppend,
    remove: direccionesDirConyRemove,
  } = useFieldArray({ control, name: "DirecccionesCony" });

  const [show, setShow] = useState(false);
  const [idDireccion, setIdDireccion] = useState(0);
  const [obtenerUbigeo, setObtenerUbigeo] = useState(undefined);
  // const [obtenerDomicilio, setObtenerDomicilio] = useState(undefined)
  /* const [contador, setContador] = useState(watch(`Direcciones`).length); */

  // let obtenerDomicilio;

  // console.log(watch(`Direcciones`).length);

  const [showDirCony, setShowDirCony] = useState(false);
  const [contadorDirCony, setContadorDirCony] = useState(0);

  const handleClose = () => {
    setShow(false);
    setIdDireccion(0);
    setObtenerUbigeo(undefined);
    setValue("Ubigeo", undefined);
    setValue("Domicilio", undefined);
  };
  const handleShow = () => setShow(true);

  const registrarDireccion = async () => {
    
    if (idDireccion > 0) {
      
      const dataPOSTEditar = {
        TipDocPer: watch("TipoDoc"),
        NumDocPer: watch("NumDoc"),
        DomiDir: watch("Domicilio"),
        UbigeoDir: watch("Ubigeo").TUbiCod,
        DirIdPer: idDireccion,
      };
      
      const { status: status1,message } = await direccionEditar(dataPOSTEditar);
      
    } else {
      
      const dataPOSTRegistrar = {
        TipDocPer: watch("TipoDoc"),
        NumDocPer: watch("NumDoc"),
        DomiDir: watch("Domicilio"),
        UbigeoDir: watch("Ubigeo").TUbiCod,
      };

      // console.log(dataPOST);
      const { status: status2,message } = await agregarDireccion(dataPOSTRegistrar);
      
    }
    
    const dataPOST = {
      TipDocPer: watch("TipoDoc"),
      NumDocPer: watch("NumDoc"),
      tokenCompañia: obtenerLocalStorage("usuario").token,
      tipo: 2,
    };
    const { status: status3, persona } = await personaBuscar(dataPOST);
    
    const persona_formateado = {
      TipoPer: persona.PerRol,
      TipoDoc: persona.PerTipDoc,
      NumDoc: persona.PerNumDoc,
      ApellidoP: persona.PerApllPat,
      ApellidoM: persona.PerApllMat,
      Nombre: persona.PerNomb,
      RazonSocial: persona.PerRazSoc,
      Telefono: persona.PerTelefono,
      Email: persona.PerEmail,
      EstCivil: persona.PerEstCivil,
      UnionHecho: persona.PerUnionHech,
      SepPatr: persona.PerSepaPatri,
      SocCony: persona.PerSocConyug,
      PartReg: persona.PerPartReg,
      OficReg: persona.PerOficReg,
      Direcciones: persona.PersonaDireccion.map((dir) => {
        return {
          Domicilio: dir.PerDirDomi,
          Ubigeo: listaUbigeo.find((u) => u.TUbiCod == dir.PerDirUbig_R),
        };
      }),
      IdCony: persona.PerIdConyug,
    };
    
    setValue(`Direcciones`, persona_formateado.Direcciones);

    setIdDireccion(0);
    setValue("Ubigeo", undefined);
    setValue("Domicilio", undefined);
    setObtenerUbigeo(undefined);
    setShow(false);
  };

  const onSubmit = async (data) => {
    const token = obtenerLocalStorage("usuario")?.token;
    const dataPOST = {
      NomPer: data.Nombre,
      ApellPatPer: data.ApellidoP,
      ApellMatPer: data.ApellidoM,
      TipDocPer: data.TipoDoc,
      NumDocPer: data.NumDoc,
      EmailPer: data?.Email || "",
      TelefonoPer: data?.Telefono || "",
      UnionHechPer: data.UnionHecho,
      SocConyugPer: data.SocCony,
      SeparPatrPer: data.SepPatr,
      PartRegPer: data.PartReg,
      OficRegPer: data.OficReg,
      RolPer: data.TipoPer,
      IdConyugPer: datosPersonaLS?.persona.IdCony || "",
      EstadoPer: "A",
      tokenCompañia: token,
    };
    // console.log(dataPOST);
    const { status } = await personaCrear(dataPOST);

    if (status === 1) {
      getDataRepresLegal(token);
      navigate(ruta_personas);
    }
    // getDataRepresLegal(token);
    // navigate(ruta_personas);
  };

  const editarPersona = async (data) => {
    const token = obtenerLocalStorage("usuario")?.token;
    const dataPOST = {
      NomPer: data.Nombre,
      ApellPatPer: data.ApellidoP,
      ApellMatPer: data.ApellidoM,
      RazSocPer: data.RazonSocial,
      TipDocPer: data.TipoDoc,
      NumDocPer: data.NumDoc,
      EmailPer: data?.Email || "",
      TelefonoPer: data?.Telefono || "",
      UnionHechPer: data.UnionHecho,
      SocConyugPer: data.SocCony,
      SeparPatrPer: data.SepPatr,
      PartRegPer: data.PartReg,
      OficRegPer: data.OficReg,
      RolPer: data.TipoPer,
      IdConyugPer: datosPersonaLS?.persona.IdCony || "",
      EstadoPer: "A",
      tokenCompañia: token,
    };
    // console.log(dataPOST);
    const { status } = await personaEditar(dataPOST);

    if (status === 1) {
      getDataRepresLegal(token);
      navigate(ruta_personas);
    }
    //getDataRepresLegal(token);
    //navigate(ruta_personas);
  };

  const irListaPersonas = () => {
    limpiarLocalStorage("persona");
    navigate(ruta_personas);
  };

  useEffect(() => {
    if (accion === "agregar") {
      if (datosPersonaLS === null) {
        // console.log("entro al use effect estcivil");
        setValue("SepPatr", "N");
        setValue("UnionHecho", "N");
        if (watch("EstCivil") != "C") {
          setValue("SocCony", "N");
          setValue("ConyEstCivil", "S");
        } else {
          setValue("SocCony", "S");
          setValue("ConyEstCivil", "C");
        }
      } else {
        limpiarLocalStorage("persona");
      }
    }
  }, [watch("EstCivil")]);

  useEffect(() => {
    if (watch("EstCivil") === "C") {
      if (watch("SepPatr") === "S") {
        setValue("SocCony", "N");
        setValue("ConyTipoDoc", "");
      } else {
        setValue("SocCony", "S");
        setValue("ConyTipoDoc", "D");
      }
    }
  }, [watch("SepPatr")]);

  // console.log(watch("Direcciones").pop())

  return (
    <>
      <div className="container pt-2">
        <form
          id="createForm"
          className="g-3"
          onSubmit={
            accion == "agregar"
              ? handleSubmit(onSubmit)
              : handleSubmit(editarPersona)
          }
        >
          <h5 className="pt-3">Datos de la Persona</h5>

          <div className="row form-section">
            {watch("TipoDoc") !== "R" ? (
              <>
                <div className="col-md-2">
                  <label className="form-label">Documento</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register("TipoDoc", {
                      required: true,
                    })}
                  >
                    {listaTipoDocumentos.map((tipDoc) => {
                      if (tipDoc.TipDocEst === "S") {
                        return (
                          <option
                            value={tipDoc.TipDocCod}
                            selected={tipDoc.TipDocCod === "D" && true}
                          >
                            {tipDoc.TipDocDes}
                          </option>
                        );
                      }
                    })}
                  </select>
                  {errors.TipoDoc && (
                    <span className="error">
                      Especificar el tipo de documento
                    </span>
                  )}
                </div>

                <div className="col-md-2">
                  <label className="form-label">Número</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly={accion === "editar" ? true : false}
                    {...register("NumDoc", {
                      required: {
                        value: true,
                        message: "Ingrese número",
                      },
                    })}
                  />
                  {errors.NumDoc && (
                    <span className="error">{errors.NumDoc.message}</span>
                  )}
                </div>

                <div className="col-md-2">
                  <label className="form-label">Ap. Paterno</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("ApellidoP", {
                      required: true,
                    })}
                  />
                  {errors.ApellidoP && (
                    <span className="error">
                      El apellido paterno es requerido
                    </span>
                  )}
                </div>
                <div className="col-md-2">
                  <label className="form-label">Ap. Materno</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("ApellidoM", {
                      required: true,
                    })}
                  />
                  {errors.ApellidoM && (
                    <span className="error">
                      El apellido materno es requerido
                    </span>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Nombres</label>
                  <input
                    type="text"
                    className="form-control"
                    /*  style={{textTransform: "uppercase"}} */
                    {...register("Nombre", { required: true })}
                  />
                  {errors.Nombre && (
                    <span className="error">El nombre es requerido</span>
                  )}
                </div>

                <div className="col-md-2">
                  <label className="form-label">Telefono</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("Telefono")}
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    {...register("Email")}
                  />
                </div>

                <div className="col-md-3">
                  <label className="form-label">Estado Civil</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register("EstCivil", {
                      required: true,
                    })}
                  >
                    {listaTipoEstadoCivil.map((estCivil) => {
                      if (estCivil.EstCivEst === "S") {
                        return (
                          <option
                            value={estCivil.EstCivCod}
                            selected={estCivil.EstCivCod === "S" && true}
                          >
                            {estCivil.EstCivDes}
                          </option>
                        );
                      }
                    })}
                  </select>
                  {errors.EstCivil && (
                    <span className="error">El estado civil es requerido</span>
                  )}
                </div>
                {watch("EstCivil") === "C" ? (
                  <>
                    <div className="col-md-3">
                      <label className="form-label">Sep. de Patrimonios</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register("SepPatr", {
                          required: true,
                        })}
                      >
                        <option value="N">No</option>
                        <option value="S">Sí</option>
                      </select>
                      {errors.SepPatr && (
                        <span className="error">
                          La Sep. de Patrimonios es requerido
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-3">
                      <label className="form-label">Union de Hecho</label>

                      <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register("UnionHecho", {
                          required: true,
                        })}
                      >
                        <option value="N">No</option>
                        <option value="S">Sí</option>
                      </select>
                      {errors.UnionHecho && (
                        <span className="error">
                          La Union de Hecho es requerido
                        </span>
                      )}
                    </div>
                  </>
                )}

                {((watch("EstCivil") === "C" && watch("SepPatr") === "S") ||
                  (watch("EstCivil") !== "C" &&
                    watch("UnionHecho") === "S")) && (
                  <>
                    <div className="col-md-2">
                      <label className="form-label">Partida</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("PartReg", {
                          required: true,
                        })}
                      />
                      {errors.PartReg && (
                        <span className="error">
                          Especificar la partida registral
                        </span>
                      )}
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Oficina</label>

                      <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register("OficReg", {
                          required: true,
                        })}
                      >
                        {listaOficinasReg?.map((item, key) => {
                          if (item.OficEst === "S") {
                            return (
                              <option value={item.OficCod}>
                                {item.OficDes}
                              </option>
                            );
                          }
                        })}
                      </select>

                      {errors.OficReg && (
                        <span className="error">
                          Especificar la oficina registral
                        </span>
                      )}
                    </div>
                    {/* <div className="col-md-5">
                  <label className="form-label">Archivo</label>
                  <input
                    {...register("pdf", {
                      validate: (value) => {
                        if (!value?.[0] && accion === "agregar")
                          return "Por favor, seleccione un archivo.";
                        if (
                          value?.[0]?.type !== "application/pdf" &&
                          accion === "agregar"
                        )
                          return "El archivo debe ser un PDF.";
                        if (
                          value?.[0]?.size > 4 * 1024 * 1024 &&
                          accion === "agregar"
                        )
                          return "El archivo no debe superar 4 MB.";
                        return true; // La validación pasó
                      },
                      required: accion === "agregar" ? true : false,
                    })}
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                    accept="application/pdf"
                  />
                  {errors.pdf && (
                    <span className="error">{errors.pdf.message}</span>
                  )}
                  {errors.pdf && errors.pdf.type === "required" && (
                    <p role="alert" className="error px-2 mb-0">
                      Por favor, seleccione un archivo
                    </p>
                  )}
                </div> */}
                  </>
                )}
              </>
            ) : (
              <>
                <div className="col-md-2">
                  <label className="form-label">Documento</label>
                  <select
                    className="form-select"
                    {...register("TipoDoc", {
                      required: true,
                    })}
                  >
                    <option value="R">RUC</option>
                  </select>
                  {errors.TipoDoc && (
                    <span className="error">
                      Especificar el tipo de documento
                    </span>
                  )}
                </div>
                <div className="col-md-2">
                  <label className="form-label">Numero</label>
                  <input
                    type="number"
                    className="form-control"
                    readOnly={accion === "editar" ? true : false}
                    {...register("NumDoc", { required: true })}
                    // maxLength={maxLengthInput}
                    // onInput={handleInputChange}
                  />
                  {errors.NumDoc && (
                    <span className="error">Ingrese número</span>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">Razón Social</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("RazonSocial", {
                      required: true,
                    })}
                  />
                  {errors.RazonSocial && (
                    <span className="error">La razón social es requerida</span>
                  )}
                </div>

                <div className="col-md-2">
                  <label className="form-label">Partida</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("PartReg", {
                      required: true,
                    })}
                  />
                  {errors.PartReg && (
                    <span className="error">
                      Especificar la partida registral
                    </span>
                  )}
                </div>

                <div className="col-md-2">
                  <label className="form-label">Oficina</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register("OficReg", {
                      required: true,
                    })}
                  >
                    {listaOficinasReg?.map((item, key) => {
                      if (item.OficEst === "S") {
                        return (
                          <option value={item.OficCod}>{item.OficDes}</option>
                        );
                      }
                    })}
                  </select>
                  {errors.OficReg && (
                    <span className="error">
                      Especificar la oficina registral
                    </span>
                  )}
                </div>
              </>
            )}

            <div className="col-md-3 mb-2">
              <label className="form-label">Tipo</label>
              <select
                className="form-select"
                aria-label="Default select example"
                {...register("TipoPer")}
              >
                <option value="">Ninguno</option>
                <option value="RL">Representante Legal</option>
                <option value="N">Notario</option>
              </select>
            </div>

            {accion === "editar" && (
              <>
                <div className="mt-3 row">
                  <h5 className="col-md-2">Direcciones</h5>
                  <div className="col-md-5">
                    <OverlayTrigger
                      overlay={<Tooltip>Agregar Direccion</Tooltip>}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-file-plus"
                        viewBox="0 0 16 16"
                        style={{
                          color:
                            estilosCompania?.boton_primario || "rgb(0, 0, 255)",
                          cursor: "pointer",
                        }}
                        onClick={handleShow}
                      >
                        <path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                      </svg>
                    </OverlayTrigger>
                  </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Direccion</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div>
                        <label className="form-label">Distrito</label>
                        <div className="">
                          <Controller
                            {...register(`Ubigeo`)}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Autocomplete
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                options={listaUbigeo}
                                getOptionLabel={(option) => option.TUbiDes}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Ingrese distrito"
                                  />
                                )}
                                defaultValue={obtenerUbigeo}
                                onChange={(e, newValue) =>
                                  field.onChange(newValue)
                                } // Actualizar el valor del campo "distrito" cuando se seleccione una opción
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <label className="form-label">Domicilio</label>
                        <input
                          type="text"
                          className="form-control"
                          //value={obtenerDomicilio}
                          {...register(`Domicilio`)}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-secundario"
                      onClick={handleClose}
                    >
                      Cerrar
                    </button>
                    <button
                      className="btn btn-main"
                      onClick={() => registrarDireccion()}
                    >
                      Registrar
                    </button>
                  </Modal.Footer>
                </Modal>

                <div className="mt-2 border">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Distrito</th>
                        <th scope="col">Direccion</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {watch('Direcciones').length > 0 ? (
                        watch(`Direcciones`).map((dir, indice) => {
                          return (
                            <tr>
                              <td>{indice + 1}</td>
                              <td>{dir?.Ubigeo?.TUbiDes}</td>
                              <td>{dir?.Domicilio}</td>
                              <td>
                                <OverlayTrigger
                                  overlay={<Tooltip>Editar</Tooltip>}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-pencil"
                                    viewBox="0 0 16 16"
                                    style={{
                                      color: estilosCompania?.boton_primario,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setIdDireccion(indice + 1);
                                      setObtenerUbigeo(dir.Ubigeo);
                                      setValue("Ubigeo", dir.Ubigeo);
                                      setValue("Domicilio", dir.Domicilio);
                                      handleShow();
                                    }}
                                  >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                  </svg>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>No hay Direccion</tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}

           
          </div>
        </form>

        <div className="text-center mx-3">
          <div className="col-12 my-3">
            <button className="btn btn-secundario" onClick={irListaPersonas}>
              Volver
            </button>
            {accion === "agregar" ? (
              <button
                type="submit"
                className="btn btn-main mx-3"
                onClick={handleSubmit(onSubmit)}
                form="createForm"
              >
                Registrar
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-main mx-3"
                onClick={handleSubmit(editarPersona)}
                form="createForm"
              >
                Guardar
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
