import React, { useEffect, useState } from 'react'
import { cargoPlaca, cargoPlacaDescargarPDF, cargoPlacaDetalle, cargoPlacaListar, obtenerProcuradoreApi, placasPendientes } from '../../api/tramiteApi';
import { useForm } from 'react-hook-form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { descargarArchivoDesdeBase64, obtenerLocalStorage } from '../../resources/funciones';
import Swal from 'sweetalert2'

export const BandejaPlaca = () => {

    const [listado, setListado] = useState([])
    const [listadoCargos, setListadoCargos] = useState([])
    const [orden, setOrden] = useState({
        columna: 'sede', // Columna inicial para ordenar
        direccion: 'asc', // Dirección inicial del orden ('asc' o 'desc')
    });

    const [modo, setModo] = useState('ADD')
    const [procuradores, setProcuradores] = useState([]);
    const [procuradorSelect, setprocuradorSelect] = useState(null);

    const [abrirModal, setAbrirModal] = useState(false);
    const estilosCompania = obtenerLocalStorage("usuario")?.estilo;
    const { register, handleSubmit, watch, formState: { errors } } = useForm();



    let fecha = new Date();
    fecha.setDate(fecha.getDate());
    let fechaActual = fecha.toISOString().substring(0, 10);

    fecha.setDate(fecha.getDate() - 20);

    let fechaAyer = fecha.toISOString().substring(0, 10);

    const handleOrdenar = (columna) => {
        const nuevaDireccion =
            orden.columna === columna && orden.direccion === 'asc' ? 'desc' : 'asc';
        ordenarDatos(columna, nuevaDireccion);
    };

    const ordenarDatos = (columna, direccion) => {
        const datosOrdenados = [...listado].sort((a, b) => {
            let valorA = a[columna] ?? "";
            let valorB = b[columna] ?? "";

            // Comprobando el tipo de valor
            if (typeof valorA === 'number' && typeof valorB === 'number') {
                return direccion === 'asc' ? valorA - valorB : valorB - valorA;
            } else if (typeof valorA === 'string' && typeof valorB === 'string') {
                return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
            } else if (valorA instanceof Date && valorB instanceof Date) {
                return direccion === 'asc' ? valorA - valorB : valorB - valorA;
            }

            // Comparación por defecto (asumiendo que son strings)
            return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
        });

        setListado(datosOrdenados);
        setOrden({ columna, direccion });
    };


    const handleCheckboxChange = (id) => {
        setListado((prevData) =>
            prevData.map((item) =>
                item.placa === id ? { ...item, check: !item.check } : item
            )
        );
    };



    const handleCheckboxAllChange = () => {
        const allChecked = listado.every((item) => item.check);
        setListado((prevData) =>
            prevData.map((item) => ({ ...item, check: !allChecked }))
        );
    };

    const procuradorSeleccionado = (e) => {
        setprocuradorSelect(e.target.value);
    };




    const closeModal = () => {
        setAbrirModal(false);
    };


    const agregarCargo = async () => {
        setModo('ADD')
        const resultData = await obtenerProcuradoreApi();
        setProcuradores(resultData.data.ListaProcuradores);
        setListado(resultData.data.listaPlacas)
        setAbrirModal(true)
    }

    const cargoDetalle = async (item) => {
        setModo('UPD')
        let datapost = {
            id: item.id
        }
        let { data } = await cargoPlacaDetalle(datapost)
        setListado(data)
        setAbrirModal(true)
    }

    const descargarPlacas = async (item) => { 
        
        let datapost = {
            id: item.id
        }
        const base64Placa = await cargoPlacaDescargarPDF(datapost)        
        descargarArchivoDesdeBase64(base64Placa.data,`Cargo_placa_${item.id}.pdf`)
    }

    const registrarCargoPlaca = async (e) => {
        e.preventDefault()
        let listaPlacas = listado.filter(item =>
            item.check === true
        )

        if (procuradorSelect && listaPlacas.length > 0) {
            let dataPost = {
                "procurador": procuradorSelect,
                "placas": listaPlacas
            }
            const respuesta = await cargoPlaca(dataPost);
            getListadoCargo()
            Swal.fire({
                text: "Se registro exitosamente el cargo",
                icon: 'success'
            })
            setAbrirModal(false)

        } else
            Swal.fire({
                text: "Seleccione un produrador y al menos una placa",
                icon: 'info'
            })
    }

    useEffect(() => {

        getListadoCargo()
    }, [])



    const getListadoCargo = async () => {
        let { data } = await cargoPlacaListar()
        setListadoCargos(data)
    }


    return (
        <div className="container-fluid p-0 m-0">

            <div className="container">
                <h4 className="text-center pb-3 pt-3 ">Bandeja de Entregas</h4>

                <div className="row m-0 pt-3 pb-3 border">
                    <div className="col-12 col-md-2">
                        <label className="form-label ">Desde</label>
                        <input className="form-control form-control-sm" type="date" defaultValue={fechaAyer} {...register("Desde")} />
                    </div>
                    <div className="col-12 col-md-2">
                        <label className="form-label">Hasta</label>
                        <input className="form-control form-control-sm" type="date" defaultValue={fechaActual} {...register("Hasta")} />
                    </div>

                    <div className="col-12 col-md-2">
                        <label className="form-label">Placa</label>
                        <input type="text" className="form-control form-control-sm px-1" {...register("Tramite")} />
                    </div>

                    <div className="col-12 col-md-4">
                        <label className="form-label">Procurador</label>
                        <input type="text" className="form-control form-control-sm px-1" {...register("Cliente")} />
                    </div>

                    <div className="col-md-2 d-flex justify-content-center align-items-end">
                        <button className="col-md-12 btn btn-sm btn-main"                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>


            <div className="container pt-3">

                <button className='btn btn-main rounded-0 rounded-top ' onClick={agregarCargo}>Agregar </button>
                <table className='table  border table-striped table-responsive'>
                    <thead>
                        <tr>
                            <th className='bold' onClick={() => handleOrdenar('fecha')}>Fecha
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th className='bold  ' onClick={() => handleOrdenar('encargado')}>Encargado
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th className='bold  ' onClick={() => handleOrdenar('estado')}>Estado
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                </svg>
                            </th>
                            <th>Placas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listadoCargos.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-light" : "bg-white"}>

                                <td>{item.fecha}</td>
                                <td>{item.procurador}</td>
                                <td> {item.estado}</td>
                                <td>
                                    <OverlayTrigger overlay={<Tooltip><span>Placas registradas</span></Tooltip>} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" className="bi bi-eye" style={{
                                            color: estilosCompania?.boton_primario,
                                            cursor: "pointer",
                                        }} onClick={e => cargoDetalle(item)}>
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                        </svg>
                                    </OverlayTrigger>
                                    <span className="pe-2"></span>
                                    <OverlayTrigger overlay={<Tooltip><span>Descargar documentos para recoger en AAP</span></Tooltip>} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="currentColor" className="bi bi-download" style={{
                                            color: estilosCompania?.boton_primario,
                                            cursor: "pointer",
                                        }} onClick={e => descargarPlacas(item)}>
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                        </svg>
                                    </OverlayTrigger>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                {abrirModal && (
                    <div className="modal fade show d-block mda" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Cargo de recojo de placa</h5>
                                            <button type="button" className="btn-close" onClick={closeModal}></button>
                                        </div>
                                        <div className="modal-body">
                                            {modo == "ADD" ?
                                                <>
                                                    <label className="form-label bold" htmlFor="form-sucursal">Procurador</label>
                                                    <select name="procurador" id="procurador" className='form-select mb-4' onChange={procuradorSeleccionado} >
                                                        <option value="">Seleccione un procurador</option>
                                                        {
                                                            procuradores?.map((procurador, index) => (
                                                                <option key={index} value={procurador.id}>{procurador.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </>
                                                : <>
                                                </>
                                            }


                                            <table className='table border table-striped table-responsive'>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "5rem" }} className='bold' >
                                                            <input className="bold" style={{ width: "1.1rem", height: "1.1rem" }} type="checkbox" value="" id="" onChange={handleCheckboxAllChange} />
                                                            &nbsp; All
                                                        </th>
                                                        <th className='bold' onClick={() => handleOrdenar('placa')}>Placa
                                                            &nbsp;
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                                            </svg>
                                                        </th>
                                                        <th className='bold  ' onClick={() => handleOrdenar('sede')}>Sede
                                                            &nbsp;
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                                            </svg>
                                                        </th>
                                                        <th className='bold  ' onClick={() => handleOrdenar('estado')}>Estado
                                                            &nbsp;
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                                                            </svg>
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listado.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" style={{ width: "1.3rem", height: "1.3rem" }} type="checkbox" value="" id="defaultCheck1" checked={item.check} onChange={() => handleCheckboxChange(item.placa)} />
                                                                </div>
                                                            </td>
                                                            <td>{item.placa}</td>
                                                            <td>{item.sede}</td>
                                                            <td>{item.estado}</td>
                                                        </tr>

                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="container d-flex justify-content-around">
                                                <div className="row ">
                                                    <div className='col-6'>
                                                        <button className='btn btn-danger' onClick={closeModal}>Cancelar</button>
                                                    </div>
                                                    {modo == "ADD" ?
                                                        <div className='col-6'>
                                                            <button className='btn text-white' style={{ backgroundColor: estilosCompania?.boton_primario }} onClick={registrarCargoPlaca} >Confirmar</button>
                                                        </div>
                                                        :
                                                        <></>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>



        </div >
    )
}