import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
// import { archivosIOFE, archivosOtros } from '../../data/data-archivos';
import {
  descargarArchivoDesdeBase64,
  formatearFecha,
  obtenerNombreYArchivoBase64,
} from "../../resources/funciones";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ruta_historial } from "../../constants/constants-rutas-codigo";
import { getArchivo, getArchivos, subirArchivo } from "../../api/gestorApi";
import { MdOutlinePreview, MdOutlineFileDownload } from "react-icons/md";
import { VisualizadorArchivos } from "./VisualizadorArchivos";

const colors = ['#90c2ff', '#ebd2a4', '#90c2ff', '#7bbfc3', '#c3c3c3', '#ece9d2', '#d9effc', '#c8bcb1', '#ecad8f', '#c1cd97']

export const GestorArchivos = () => {
  const location = useLocation();
  const idTramite = location?.state?.idTramite || "";

  const archivos = location?.state?.archivos.sort((a, b) => b.Orden - a.Orden) || []

  const [listaArchivos, setListaArchivos] = useState(archivos);



  const [nombreArchivoVisualizar, setNombreArchivoVisualizar] = useState(""); // nombre del archivo especifico para visualizar
  const [listaArchivosVisualizar, setListaArchivosVisualizar] = useState([]); // lista de todos los archivos para visualizar
  const [numeroArchivoInicial, setNumeroArchivoInicial] = useState(1); // numero inicial del archivo que se visualizara

  // Estado para abrir el visualizador de archivos
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const agregarArchivos = async () => {
    let fileSelected = null;
    let tipoSeleccionado = null;

    const { isConfirmed } = await Swal.fire({
      title: "Selecciona el archivo",
      html: `
        <br/>   
        <select id="archivoSelector" style="width: 100%;" class="form-select">
              <option value="1">Certificados adicionales</option> 
              <option value="4">Aclaratorias</option> 
              <option value="2">Partida de matrimonio, partida de defuncion</option> 
              <option value="3">Cliente (otros)</option>               
            </select>
            <input id="inputFile" class="form-control" type="file" accept=".pdf" style="width: 100%; margin-top: 10px;">
          `,
      showCancelButton: true,
      confirmButtonText: "Guardar",
      showLoaderOnConfirm: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false, // Evitar que el modal se cierre haciendo clic fuera de él
      preConfirm: () => {
        tipoSeleccionado = document.getElementById("archivoSelector").value;
        fileSelected = document.getElementById("inputFile").files[0];
        return { tipo: tipoSeleccionado, file: fileSelected };
      },
      customClass: {
        confirmButton: "btn-subir",
        cancelButton: "btn-cancelar",
      },
    });

    if (isConfirmed) {
      if (!tipoSeleccionado || !fileSelected) {
        // Mostrar un botón "OK" para ejecutar agregarArchivos() nuevamente
        const { isConfirmed } = await Swal.fire({
          title: "Ambos campos son obligatorios",
          confirmButtonText: "OK",
          showCancelButton: false,
          allowOutsideClick: false, // Evitar que el modal se cierre haciendo clic fuera de él
        });

        if (isConfirmed) {
          // Ejecutar la función agregarArchivos() nuevamente
          agregarArchivos();
        }
      } else if (tipoSeleccionado && fileSelected) {
        if (
          fileSelected.type === "application/pdf" ||
          fileSelected.type === "text/xml"
        ) {
          const respData = guardarArchivo(fileSelected, tipoSeleccionado);
          if (respData) {
            Swal.fire("¡Archivo subido correctamente!", "", "success");
          } else {
            Swal.fire("Hubo un error al subir el archivo", "", "error");
          }
        } else {
          Swal.fire("Tipo de archivo no válido", "", "error");
        }
      }
    }
  };

  const guardarArchivo = async (file, tipo) => {

    try {
      const nombreArchivo = file.name;
      // const tamanio = tamanioEnKB >= 1024 ? (tamanioEnKB / 1024).toFixed(2) + ' MB' : tamanioEnKB.toFixed(2) + ' KB';
      const { archivoBase64 } = await obtenerNombreYArchivoBase64(file);

      const nuevoArchivo = {
        idTramite: idTramite,
        Nom_archv: nombreArchivo,
        pdfBase64: archivoBase64.slice(28),
        tipo: tipo,
      };

      const { status } = await subirArchivo(nuevoArchivo);
      if (status === 1) {
        const dataPOST = {
          TramitId: idTramite,
        }
        const listaNuevoArchivo = await getArchivos(dataPOST);
        setListaArchivos(listaNuevoArchivo.archivos.sort((a, b) => b.Orden - a.Orden))
        return true
      } else {
        return false
      }

    } catch (error) {
      return false

    }
  }


  const descargarArchivo = async (idTramite, nombreArchivo) => {
    const dataPOST = {
      TramitId: idTramite,
      nombreArchivo: nombreArchivo,
    };
    const { archivo } = await getArchivo(dataPOST);
    descargarArchivoDesdeBase64(archivo, nombreArchivo);
  };

  const irBandeja = () => {
    navigate(ruta_historial);
  };

  const verArchivo = (nombreArchivo, listaArchivos, numeroArchivo) => {
    setNombreArchivoVisualizar(nombreArchivo);
    setListaArchivosVisualizar(listaArchivos);
    setNumeroArchivoInicial(numeroArchivo);
    handleOpen();
  };

  return (
    <div className="p-3">
      <div className="container">
        <h4 className="text-center mt-3">{`Archivos del Trámite ${idTramite}`}</h4>

        <div className="mx-auto ps-0">
          <button
            className="btn btn-main d-flex align-items-center"
            onClick={agregarArchivos}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              className="bi bi-plus-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
              />
            </svg>
            <span className="ms-1">Agregar</span>
          </button>
        </div>

        {/* <div className="archivos-iofe text-center">
          <h5 className="p-4 text-success">Documentos para Firma (Notaria):</h5>          
        </div> */}

        {listaArchivos?.length > 0 ? listaArchivos.map((section, secIndex) => (
          section.Files.length > 0 ? (
            <div key={`archivos-${secIndex}`} className="col-12 table-responsive mt-5 mx-auto rounded-1" style={{ border: `1px solid ${colors[secIndex]}` }}>
              <table className="table  table-hover  table-borderless">
                <thead>
                  <tr>
                    <th colSpan={5} className="text-center"><h5>{section.Descripcion}</h5></th>
                  </tr>
                  {section.Files?.length > 0 ?
                    <tr>
                      <th scope="col" className="col-1 text-center"></th>
                      <th scope="col" className="col-1 text-center"></th>
                      <th scope="col" className="col-6 text-center">
                        Nombre
                      </th>
                      <th scope="col" className="col-2  text-center">
                        Fecha
                      </th>
                      <th scope="col" className="col-2 text-center">
                        Tamaño
                      </th>
                    </tr> : null}
                </thead>
                <tbody>
                  {section.Files?.length > 0 ? (
                    section.Files.map((a, index) => (
                      <tr key={index} className="align-middle">
                        <td className="col-1 text-center">
                          <MdOutlineFileDownload
                            size={25}
                            cursor={"pointer"}
                            onClick={() => descargarArchivo(idTramite, a?.Nom_archv)}
                          />
                        </td>
                        <td
                          className="col-1 text-center"
                          onClick={() => a?.Nom_archv?.split(".").pop() !== "zip" ? verArchivo(a?.Nom_archv, section.Files, index + 1) : {}}
                        >
                          {a?.Nom_archv?.split(".").pop() == "pdf" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-file-earmark-pdf"
                              viewBox="0 0 16 16"
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                              <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                            </svg>
                          )}
                          {a?.Nom_archv?.split(".").pop() == "xml" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#9700b7"
                              className="bi bi-filetype-xml"
                              viewBox="0 0 16 16"
                              style={{ cursor: "pointer" }}
                            >
                              <path
                                fillRule="evenodd"
                                d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z"
                              />
                            </svg>
                          )}
                          {a?.Nom_archv?.split(".").pop() != "xml" &&
                            a?.Nom_archv?.split(".").pop() != "zip" &&
                            a?.Nom_archv?.split(".").pop() != "pdf" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#3f87ff"
                                className="bi bi-image"
                                viewBox="0 0 16 16"
                                style={{ cursor: "pointer" }}
                              >
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12" />
                              </svg>
                            )}

                        </td>

                        <td className="col-6 text-center">{a?.Nom_archv}</td>
                        <td className="col-2 text-center">{a?.Fch_creac}</td>
                        <td className="col-2 text-center">{a?.Peso + " KB"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No hay archivos
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>) : null)) : <h3 className="text-center mt-5">No se encontraron Archivos</h3>}



        <div className="col-10 mx-auto d-flex justify-content-center mt-3">
          <button className="btn btn-secundario mx-3" onClick={irBandeja}>
            Volver
          </button>
        </div>
      </div>
      <VisualizadorArchivos
        open={open}
        handleClose={handleClose}
        idTramite={idTramite}
        nombreArchivoVisualizar={nombreArchivoVisualizar}
        listaArchivosVisualizar={listaArchivosVisualizar}
        numeroArchivoInicial={numeroArchivoInicial}
      />
    </div>
  );
};
