import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { placasPendientes, placaEntregar } from '../../api/tramiteApi';
import Swal from 'sweetalert2'



export const PlacaEntrega = () => {

  const [listado, setListado] = useState([])
  const [orden, setOrden] = useState({
    columna: 'sede', // Columna inicial para ordenar
    direccion: 'asc', // Dirección inicial del orden ('asc' o 'desc')
  });

  const { register, handleSubmit, formState: { errors } } = useForm();


  const [checkActive, setCheckActive] = useState(false)


  const handleOrdenar = (columna) => {
    const nuevaDireccion =
      orden.columna === columna && orden.direccion === 'asc' ? 'desc' : 'asc';
    ordenarDatos(columna, nuevaDireccion);
  };

  const ordenarDatos = (columna, direccion) => {
    const datosOrdenados = [...listado].sort((a, b) => {
      let valorA = a[columna];
      let valorB = b[columna];

      // Comprobando el tipo de valor
      if (typeof valorA === 'number' && typeof valorB === 'number') {
        return direccion === 'asc' ? valorA - valorB : valorB - valorA;
      } else if (typeof valorA === 'string' && typeof valorB === 'string') {
        return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
      } else if (valorA instanceof Date && valorB instanceof Date) {
        return direccion === 'asc' ? valorA - valorB : valorB - valorA;
      }

      // Comparación por defecto (asumiendo que son strings)
      return direccion === 'asc' ? valorA.localeCompare(valorB) : valorB.localeCompare(valorA);
    });

    setListado(datosOrdenados);
    setOrden({ columna, direccion });
  };


  const handleCheckboxChange = (id) => {
    setListado((prevData) =>
      prevData.map((item) =>
        item.placa === id ? { ...item, check: !item.check } : item
      )
    );
  };


  const handleCheckboxChangeAll = () => {
    setCheckActive(!checkActive)
    setListado((prevData) =>
      prevData.map((item) =>
        item.placa ? { ...item, check: !checkActive } : item
      )
    );
  };


  useEffect(() => {

    const getUser = async () => {
      // verificar si tiene permiso
    }


    const getData = async () => {
      try {
        console.log("Entro al get data ");
        const dataPost = { "sede": "1" }
        const respuesta = await placasPendientes(dataPost);
        console.log("respuesta ", respuesta);

        setListado(respuesta.lista)
      } catch (error) {
        console.log("error ", error);
      }
    }
    getData()

  }, [])


  const onSubmit = async (data) => {
    let listaPlaca = []
    for (const item of listado) {
      if (item.check === true) {
        listaPlaca.push(item)
      }
    }

    let terminoEntrega = false
    if (listado.length === listaPlaca.length) {
      terminoEntrega = true
    }

    if (listaPlaca.length > 0) {
      let dataPost = { lista: listaPlaca, nombre: data.nombre,termino:terminoEntrega }
      const respuesta = await placaEntregar(dataPost);
      if (respuesta.status == 1) {
        const nuevaLista = await placasPendientes(dataPost);

        setListado(nuevaLista.lista)

        Swal.fire({
          title: 'Se actualizo correctamente',
          icon: 'success'
        })
      } else {
        Swal.fire({
          title: 'Hubo un error',
          text: "Intentelo nuevamente en unos minutos",
          icon: 'info'
        })
      }
    } else {
      Swal.fire({
        title: 'No hay datos por procesar',

        icon: 'info'
      })
    }

  };



  return (
    <div className="container-fluid p-0 m-0">

      <div className="container pt-2 pb-3">

        <div className="container p-0">

          <div className="container col-12 col-xs-12 col-sm-12 col-md-10 col-lg-6 border border-1 pt-3">

            <p className='fs-3 text-center bold'>Entrega de placas</p>
            <hr />


            <form id="createForm" className="g-3" onSubmit={handleSubmit(onSubmit)}>

              <div className="">
                <div for="idrecepciono" className="form-label fs-5 bold text-center">¿Quien recibio la placa?</div>
                <input type="text" {...register("nombre", {
                  required: {
                    value: true,
                    message: "Ingrese nombre",
                  }
                })} className="form-control text-capitalize" id="idrecepciono" aria-describedby="" placeholder='Ingrese Nombres Completos' />
              </div>

              {errors.nombre && (
                <span className="text-center d-block bold error">{errors.nombre.message}</span>
              )}




              <div className="mb-3 mt-3">
                <div for="exampleInputEmail1" className="form-label bold text-center">Seleccione las placas entregadas </div>


                <table className='table border table-striped table-responsive'>
                  <thead>
                    <tr>
                      <th >
                        <input className="form-check-input" style={{ width: "1.3rem", height: "1.3rem" }} type="checkbox" onClick={handleCheckboxChangeAll} />
                      </th>
                      <th className='bold p-0 pb-2 text-start ps-1' onClick={() => handleOrdenar('placa')}>PLACA
                        &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                        </svg>
                      </th>
                      <th className='bold p-0 pb-2 text-start  ps-4' onClick={() => handleOrdenar('sede')}>SEDE
                        &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-arrow-down-up" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5" />
                        </svg>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {listado ?

                      <>
                        {listado.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="form-check">
                                <input className="form-check-input" style={{ width: "1.3rem", height: "1.3rem" }}
                                  type="checkbox" value="" id="" checked={item.check} onChange={() => handleCheckboxChange(item.placa)} />
                              </div>
                            </td>
                            <td>{item.placa}</td>
                            <td> {item.sede}</td>
                          </tr>
                        ))}
                      </>
                      :
                      <tr>
                        <td className="text-center" colSpan={12}>
                          <div className="d-grid text-center ">
                            <div className="container">
                              <div className="spinner-border text-primary text-center" role="status" />
                            </div>

                          </div>
                        </td>
                      </tr>
                    }



                  </tbody>
                </table>

              </div>


              <button type="submit" className="btn btn-primary col-12" >Registrar Entrega</button>
            </form>


          </div>
        </div>

      </div>








    </div>
  )
}
