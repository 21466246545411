import { codigo_bandeja, codigo_gestor_de_archivos, codigo_enviar_iofe, codigo_personas, codigo_personas_agregar, codigo_personas_editar, codigo_tramite_agregar, codigo_tramite_anular, codigo_tramite_editar, ruta_gestor_de_archivos, ruta_historial, ruta_personas, ruta_personas_agregar, ruta_personas_editar, ruta_tramite_agregar, ruta_tramite_confirmacion, ruta_tramite_editar, ruta_tramite_pago_agregar, ruta_tramite_pago_editar, ruta_vehiculo, codigo_reportes, ruta_reportes, codigo_reportes_reportea, ruta_reportes_reportea, codigo_reportes_reportea1, ruta_reportes_reportea1, codigo_reportes_reportea2, ruta_reportes_reportea2, codigo_reportes_inmatriculaciones, ruta_reportes_inmatriculaciones, codigo_reportes_general, ruta_reportes_general, codigo_externo_placaEntrega, ruta_placa_entrega, codigo_placa_entrega, ruta_placa_entregas, ruta_home, codigo_configuracion_iofe, ruta_configuracion_iofe, codigo_configuracion_notario, ruta_configuracion_notario, codigo_placa, ruta_externo_placa_entrega, ruta_placa } from "./constants-rutas-codigo";


export const rutasCodigosPrograma = [
    {
        codigo: codigo_bandeja,
        ruta: ruta_historial
    },
    {
        codigo: codigo_bandeja,
        ruta: ruta_home
    },
    {
        codigo: codigo_tramite_agregar,
        ruta: ruta_tramite_agregar,
    },
    {
        codigo: codigo_tramite_agregar,
        ruta: ruta_tramite_pago_agregar,
    },
    {
        codigo: codigo_tramite_agregar,
        ruta: ruta_tramite_confirmacion,
    },
    {
        codigo: codigo_tramite_editar,
        ruta: ruta_tramite_editar,
    },
    {
        codigo: codigo_tramite_editar,
        ruta: ruta_tramite_pago_editar,
    },
    {
        codigo: codigo_tramite_editar,
        ruta: ruta_vehiculo,
    },
    {
        codigo: codigo_tramite_anular,
        ruta: "",
    },
    {
        codigo: codigo_gestor_de_archivos,
        ruta: ruta_gestor_de_archivos,
    },
    {
        codigo: codigo_enviar_iofe,
        ruta: "",
    },
    {
        codigo: codigo_personas,
        ruta: ruta_personas
    },
    {
        codigo: codigo_personas_agregar,
        ruta: ruta_personas_agregar
    },
    {
        codigo: codigo_personas_editar,
        ruta: ruta_personas_editar
    },
    {
        codigo: codigo_reportes,
        ruta: "reportes"
    },
    {
        codigo: codigo_reportes_inmatriculaciones,
        ruta: ruta_reportes_inmatriculaciones
    },
    {
        codigo: codigo_reportes_general,
        ruta: ruta_reportes_general
    },
    {
        codigo: codigo_configuracion_iofe,
        ruta: ruta_configuracion_iofe
    },
    {
        codigo: codigo_configuracion_notario,
        ruta: ruta_configuracion_notario 
    },
    {
        codigo: codigo_externo_placaEntrega,
        ruta: ruta_externo_placa_entrega
    },
    {
        codigo: codigo_placa,
        ruta: ruta_placa
    } 
]