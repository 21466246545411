import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

import Tramite from "../components/inmatriculacion/Tramite";
import { TramitePagos } from "../components/inmatriculacion/TramitePagos";
import { Vehiculo } from "../components/inmatriculacion/Vehiculo";
import { ConfirmacionRegistro } from "../components/inmatriculacion/ConfirmacionRegistro";
import { SideBar } from "../components/layout/Sidebar";
import { Bandeja } from "../components/inmatriculacion/Bandeja";
import { Login } from "../components/Login";
import {
  ruta_configuracion_iofe,
  ruta_configuracion_notario,
  ruta_externo_placa_entrega,
  ruta_gestor_de_archivos,
  ruta_historial,
  ruta_home,
  ruta_login,
  ruta_personas,
  ruta_personas_agregar,
  ruta_personas_editar,
  ruta_placa,
  ruta_placa_entrega,
  ruta_placa_entregas,
  ruta_reportes_dashboard,
  ruta_reportes_general,
  ruta_reportes_inmatriculaciones,
  ruta_reportes_reportea1,
  ruta_reportes_reportea2,
  ruta_sin_acceso,
  ruta_tramite_agregar,
  ruta_tramite_confirmacion,
  ruta_tramite_editar,
  ruta_tramite_pago_agregar,
  ruta_tramite_pago_editar,
  ruta_vehiculo,
} from "../constants/constants-rutas-codigo";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { GestorArchivos } from "../components/gestor-de-archivos/GestorArchivos";
import { cambiarColores, limpiarLocalStorage, obtenerLocalStorage } from "../resources/funciones";
import { ConexionExterna } from "../components/conexionExterna/ConexionExterna";
import { IniciandoSesion } from "../components/conexionExterna/IniciandoSesion";
import { ScrollToTop } from "../components/layout/ScrollToTop";
import { rutasCodigosPrograma } from "../constants/constants-arreglo-rutas-codigo";
import { verificarUsuario } from "../api/loginApi";
import { SinAcceso } from "../components/SinAcceso";
import { ListaPersonas } from "../components/personas/ListaPersonas";
import { Persona } from "../components/personas/Persona";
import { Inmatriculaciones } from '../components/reportes/Inmatriculaciones'
import { General } from '../components/reportes/General'
import { Dashboard } from "../components/reportes/Dashboard";
import { PlacaEntrega } from "../components/entrega/PlacaEntrega";
import { BandejaPlaca } from "../components/entrega/BandejaPlaca";
import { Iofe } from "../components/configuracion/Iofe";
import { Notario } from "../components/configuracion/Notario";
import { LoginAutohub } from "../components/LoginAutohub";


export const AppRouter = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const ruta = location.pathname;

  const methods = useForm(); // Inicializa el formulario

  const usuario = obtenerLocalStorage("usuario");

  const estilosCompania = usuario?.estilo;

  const [mostrarPaginaAutenticado, setMostrarPaginaAutenticado] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true)

  const validarAutenticacion = async (token, url) => {
    if (ruta !== "/") {
      setIsLoading(true);

      const parametro = rutasCodigosPrograma.find((r) => r.ruta === url);

      const dataPOST = {
        token: token,
        // url_modulo: url,
        codigo: parametro?.codigo || "",
      };
      const { autenticado, message } = await verificarUsuario(dataPOST);
      // console.log(autenticado);
      setIsLoading(false);
      setMostrarPaginaAutenticado(autenticado);

      if (!autenticado && message === "El token es invalido") {
        limpiarLocalStorage("usuario");
        navigate(ruta_login);
      }
    } else {
      setMostrarPaginaAutenticado(true);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    // console.log(usuario);
    if (usuario?.token) {
      // console.log(usuario?.token)
      validarAutenticacion(usuario?.token, ruta);

      if (ruta === ruta_historial || ruta === ruta_personas) {
        limpiarLocalStorage("tramite");
        limpiarLocalStorage("pagos");
        limpiarLocalStorage("persona");
      }

      if (ruta === ruta_login) {
        limpiarLocalStorage("usuario");
      }
    }
  }, [ruta]);

  useEffect(() => {
    // Guardar los colores al cargar la pagina
    if (estilosCompania) {
      const colores = [
        {
          name: "--color-fondo-menu-primario",
          color: estilosCompania?.fondo_menu,
        },
        {
          name: "--color-texto-nav-item",
          color: estilosCompania?.text_opciones,
        },
        {
          name: "--color-fondo-nav-item-activo",
          color: estilosCompania?.fond_opc_activo,
        },
        {
          name: "--color-texto-nav-item-activo",
          color: estilosCompania?.text_opc_activo,
        },
        {
          name: "--color-button-primario",
          color: estilosCompania?.boton_primario,
        },
        {
          name: "--color-button-primario-hover",
          color: estilosCompania?.boton_primario_activo,
        },
        {
          name: "--color-button-primario-text",
          color: estilosCompania?.text_boton_primario,
        },
        {
          name: "--color-button-secundario",
          color: estilosCompania?.boton_secundario,
        },
        {
          name: "--color-button-secundario-hover",
          color: estilosCompania?.boton_secundario_activo,
        },
        {
          name: "--color-button-secundario-text",
          color: estilosCompania?.text_boton_secundario,
        },
      ];
      cambiarColores(colores);
    } else {
      // const colores = [
      //     { name: '--color-fondo-menu-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_FONDO_MENU_PRIMARIO },
      //     { name: '--color-texto-nav-item', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_TEXTO_NAV_ITEM },
      //     { name: '--color-fondo-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_FONDO_NAV_ITEM_ACTIVO },
      //     { name: '--color-texto-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_TEXTO_NAV_ITEM_ACTIVO },
      //     { name: '--color-button-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO },
      //     { name: '--color-button-primario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_HOVER },
      //     { name: '--color-button-primario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_TEXT },
      //     { name: '--color-button-secundario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO },
      //     { name: '--color-button-secundario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_HOVER },
      //     { name: '--color-button-secundario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_TEXT},
      // ];
      // cambiarColores(colores);
    }

    // getCompaniasYSedesPorUsuario(usuarioAutenticado?.token)
  }, []);

  //console.log(ruta);

  return (
    <div className="App">
      {/*{ruta != "/inmatriculacion/login" && ruta != "/" && ruta != "/conexionExterna/*" ? (*/}

      

      {usuario != null ? (
        mostrarPaginaAutenticado ? (
          <FormProvider {...methods}>
            <ScrollToTop />

            <SideBar>
              <Routes>
                {/* <Route path="*" element={<Login />}></Route> */}
                <Route path={ruta_home} element={<Bandeja />} />

                <Route path={ruta_tramite_agregar} element={<Tramite />} />
                <Route path={ruta_tramite_editar} element={<Tramite />} />
                <Route
                  path={ruta_tramite_pago_agregar}
                  element={<TramitePagos />}
                />
                <Route
                  path={ruta_tramite_pago_editar}
                  element={<TramitePagos />}
                />
                <Route path={ruta_historial} element={<Bandeja />} />
                <Route path={ruta_vehiculo} element={<Vehiculo />} />

                <Route
                  path={ruta_tramite_confirmacion}
                  element={<ConfirmacionRegistro />}
                />
                <Route
                  path={ruta_gestor_de_archivos}
                  element={<GestorArchivos />}
                />

                <Route path={ruta_personas} element={<ListaPersonas />} />
                <Route path={ruta_personas_agregar} element={<Persona />} />
                <Route path={ruta_personas_editar} element={<Persona />} />
                <Route path={ruta_reportes_inmatriculaciones} element={<Inmatriculaciones />} />
                <Route path={ruta_reportes_general} element={<Dashboard />} />
                <Route path={ruta_reportes_dashboard} element={<Dashboard />} />

                

                <Route path={ruta_configuracion_iofe} element={<Iofe />} />
                <Route path={ruta_configuracion_notario} element={<Notario />} />
                
                <Route path={ruta_placa} element={<BandejaPlaca />} />
                

                <Route path={ruta_sin_acceso} element={<SinAcceso />} />
              </Routes>
            </SideBar>
          </FormProvider>
        ) : (
          <SideBar>
            {isLoading ? (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <SinAcceso />
            )}
          </SideBar>
        )
      ) : (
        <Routes>

          <Route path="/" element={<Navigate to="/inmatriculacion/login" />} />
          <Route path={ruta_login} element={<Login />}></Route>
          <Route path="/placa/login" element={<LoginAutohub />}></Route>
          <Route path={ruta_externo_placa_entrega} element={<PlacaEntrega />} />
          
          <Route
            path="/conexionExterna/:token"
            element={<ConexionExterna />}
          ></Route>
          <Route path="/conectando" element={<IniciandoSesion />}></Route>
          <Route path="/*" element={<Navigate to="/inmatriculacion/login" />} />
        </Routes>
      )}
    </div>
  );
};
