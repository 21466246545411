import Modal from '@mui/material/Modal';
import { Archivo } from './Archivo';

export const VisualizadorArchivos = ({open, handleClose, idTramite, nombreArchivoVisualizar, listaArchivosVisualizar, numeroArchivoInicial}) => {

    // console.log('numeroArchivoInicial',numeroArchivoInicial)
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ bgcolor: 'background.paper'}}
                size="xl" 
            >
                <div style={{width: "72%", height: "100%", margin: "auto"}}>

                    <Archivo listaArchivosVisualizar={listaArchivosVisualizar} idTramite={idTramite} nombreArchivoVisualizar={nombreArchivoVisualizar} numeroArchivoInicial={numeroArchivoInicial}/>
                </div>
            </Modal>
        </div>
    )
}
