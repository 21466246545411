import { api } from "./configApi";

export const obtenerConstantes = async () => {
    try {
        const res = await api.post("api/common/ObtenerData")
        
        if (res.data.status === 1) {
            var arr = res.data.TipoDocumentos
            //var last = arr.pop()

            var arrInmat = res.data.TipoInmatriculacion
            var lastInmat = arrInmat.pop()
            arrInmat.unshift(lastInmat)

            var arrEstCont = res.data.EstadoContrato
            var lastEstCont = arrEstCont.shift()
            arrEstCont.push(lastEstCont)

            return {
                status: res.data.status,
                message: res.data.message,
                // ubigeo: res.data?.Ubigeo,
                tipoInmatriculacion: arrInmat,
                estadoContrato: arrEstCont,
                tipoDocumentos: arr,
                tipoEstadoCivil: res.data.TipoEstadoCivil,
                estados: res.data.ListaEstado,
                tipoMoneda: res.data.TipoMoneda.reverse(),
                bancos: res.data.Bancos, 
                oficinaReg: res.data.Oficina_Registral,
                estPersona: res.data.Estado_Persona
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: 0, 
                message: error.response.data?.message || "Error en la busqueda",
                // ubigeo: [],
                tipoInmatriculacion: [],
                estadoContrato: [],
                tipoDocumentos: [],
                tipoEstadoCivil: [],
                estados: [],
                tipoMoneda: [],
                bancos: [],
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            // ubigeo: [],
            tipoInmatriculacion: [],
            estadoContrato: [],
            tipoDocumentos: [],
            tipoEstadoCivil: [],
            estados: [],
            tipoMoneda: [],
            bancos: [],
        };
    }
}