import React, { useEffect, useState } from "react";
import { obtenerConstantes } from "../api/dataSelector";
import { repLegalesListar } from "../api/personaApi";
import { obtenerSucursales } from "../api/tramiteApi";
import { codOperaciones, combustible, puertos, regimenes } from "../constants/constants-selectores";
import { ubigeo } from "../constants/ubigeo";
import { obtenerLocalStorage } from "../resources/funciones";
import { DataContext } from "./DataContext";

// const oficina_registrales = [
//   {
//    "codigo": 1,
//    "oficina": "ANDAHUAYLAS"
//   },
//   {
//    "codigo": 2,
//    "oficina": "APURIMAC"
//   },
//   {
//    "codigo": 3,
//    "oficina": "AREQUIPA"
//   },
//   {
//    "codigo": 4,
//    "oficina": "AYACUCHO"
//   },
//   {
//    "codigo": 5,
//    "oficina": "BARRANCA"
//   },
//   {
//    "codigo": 6,
//    "oficina": "BAGUA"
//   },
//   {
//    "codigo": 7,
//    "oficina": "CALLAO"
//   },
//   {
//    "codigo": 8,
//    "oficina": "CAJAMARCA"
//   },
//   {
//    "codigo": 9,
//    "oficina": "CAMANA"
//   },
//   {
//    "codigo": 10,
//    "oficina": "CAÑETE"
//   },
//   {
//    "codigo": 11,
//    "oficina": "CASMA"
//   },
//   {
//    "codigo": 12,
//    "oficina": "CASTILLA"
//   },
//   {
//    "codigo": 13,
//    "oficina": "CHACHAPOYAS"
//   },
//   {
//    "codigo": 14,
//    "oficina": "CHEPEN"
//   },
//   {
//    "codigo": 15,
//    "oficina": "CHICLAYO"
//   },
//   {
//    "codigo": 16,
//    "oficina": "CHIMBOTE"
//   },
//   {
//    "codigo": 17,
//    "oficina": "CHINCHA"
//   },
//   {
//    "codigo": 18,
//    "oficina": "CHOTA"
//   },
//   {
//    "codigo": 19,
//    "oficina": "CORONEL PORTILLO"
//   },
//   {
//    "codigo": 20,
//    "oficina": "CUSCO"
//   },
//   {
//    "codigo": 21,
//    "oficina": "HUACHO"
//   },
//   {
//    "codigo": 22,
//    "oficina": "HUAMACHUCO"
//   },
//   {
//    "codigo": 23,
//    "oficina": "HUANCAVELICA"
//   },
//   {
//    "codigo": 24,
//    "oficina": "HUANCAYO"
//   },
//   {
//    "codigo": 25,
//    "oficina": "HUANTA"
//   },
//   {
//    "codigo": 26,
//    "oficina": "HUANUCO"
//   },
//   {
//    "codigo": 27,
//    "oficina": "HUARAL"
//   },
//   {
//    "codigo": 28,
//    "oficina": "HUARAZ"
//   },
//   {
//    "codigo": 29,
//    "oficina": "ICA"
//   },
//   {
//    "codigo": 30,
//    "oficina": "ILO"
//   },
//   {
//    "codigo": 31,
//    "oficina": "IQUITOS"
//   },
//   {
//    "codigo": 32,
//    "oficina": "ISLAY"
//   },
//   {
//    "codigo": 33,
//    "oficina": "JAEN"
//   },
//   {
//    "codigo": 34,
//    "oficina": "JUANJUI"
//   },
//   {
//    "codigo": 35,
//    "oficina": "JULIACA"
//   },
//   {
//    "codigo": 36,
//    "oficina": "LIMA"
//   },
//   {
//    "codigo": 37,
//    "oficina": "MADRE DE DIOS"
//   },
//   {
//    "codigo": 38,
//    "oficina": "MOQUEGUA"
//   },
//   {
//    "codigo": 39,
//    "oficina": "MOYOBAMBA"
//   },
//   {
//    "codigo": 40,
//    "oficina": "NAZCA"
//   },
//   {
//    "codigo": 41,
//    "oficina": "OTUZCO"
//   },
//   {
//    "codigo": 42,
//    "oficina": "PASCO"
//   },
//   {
//    "codigo": 43,
//    "oficina": "PISCO"
//   },
//   {
//    "codigo": 44,
//    "oficina": "PIURA"
//   },
//   {
//    "codigo": 45,
//    "oficina": "PUNO"
//   },
//   {
//    "codigo": 46,
//    "oficina": "QUILLABAMBA"
//   },
//   {
//    "codigo": 47,
//    "oficina": "SAN PEDRO DE LLOC"
//   },
//   {
//    "codigo": 48,
//    "oficina": "SATIPO"
//   },
//   {
//    "codigo": 49,
//    "oficina": "SELVA CENTRAL"
//   },
//   {
//    "codigo": 50,
//    "oficina": "SICUANI"
//   },
//   {
//    "codigo": 51,
//    "oficina": "SULLANA"
//   },
//   {
//    "codigo": 52,
//    "oficina": "TACNA"
//   },
//   {
//    "codigo": 53,
//    "oficina": "TARAPOTO"
//   },
//   {
//    "codigo": 54,
//    "oficina": "TARMA"
//   },
//   {
//    "codigo": 55,
//    "oficina": "TINGO MARIA"
//   },
//   {
//    "codigo": 56,
//    "oficina": "TRUJILLO"
//   },
//   {
//    "codigo": 57,
//    "oficina": "TUMBES"
//   },
//   {
//    "codigo": 58,
//    "oficina": "YURIMAGUAS"
//   },
//   {
//    "codigo": 59,
//    "oficina": "RENIEC"
//   },
//   {
//    "codigo": 60,
//    "oficina": "PUCALLPA"
//   },
//   {
//    "codigo": 61,
//    "oficina": "MAYNAS"
//   },
//   {
//    "codigo": 62,
//    "oficina": "LA MERCED"
//   }
//  ]

export const DataProvider = ({ children }) => {
  const [listaUbigeo, setListaUbigeo] = useState(ubigeo);
  const [listaTipoInmatriculacion, setListaTipoInmatriculacion] = useState([]);
  const [listaEstadoContrato, setListaEstadoContrato] = useState([]);
  const [listaTipoDocumentos, setListaTipoDocumentos] = useState([]);
  const [listaTipoEstadoCivil, setListaTipoEstadoCivil] = useState([]);
  const [listaTipoContratante, setListaTipoContrate] = useState([]);
  const [listaEstado, setListaEstado] = useState([]);
  const [listaTipoMoneda, setListaTipoMoneda] = useState([]);
  const [listaBancos, setListaBancos] = useState([]);
  const [listaOficinasReg, setListaOficinasReg] = useState([]);
  const [listaEstadosPersona, setListaEstadosPersona] = useState([])

  const [listaPuertos, setListaPuertos] = useState(puertos);
  const [listaRegimenes, setListaRegimenes] = useState(regimenes);

  const [listaCombustible, setListaCombustible] = useState(combustible)

  const [listaCompradoresContext, setListaCompradoresContext] = useState([]);

  const [listaRepLegal, setListaRepLegal] = useState([]);

  const [listaOperaciones, setListaOperaciones] = useState(codOperaciones);

  const token = obtenerLocalStorage("usuario")?.token
  // console.log(token)

  const getDataSelector = async () => {
    const {
      tipoInmatriculacion,
      estadoContrato,
      tipoDocumentos,
      tipoEstadoCivil,
      estados,
      tipoMoneda,
      bancos,
      oficinaReg,
      estPersona
    } = await obtenerConstantes();

    // const {sucursales} = await obtenerSucursales({token: token})

    

    // //console.log("ejecuta la api") 
    
    setListaTipoInmatriculacion(tipoInmatriculacion);
    setListaEstadoContrato(estadoContrato);
    setListaTipoDocumentos(tipoDocumentos);
    setListaTipoEstadoCivil(tipoEstadoCivil);
    setListaEstado(estados);
    setListaTipoMoneda(tipoMoneda);
    setListaBancos(bancos);
    setListaOficinasReg(oficinaReg); 
    setListaEstadosPersona(estPersona)
  };

  const getDataRepresLegal = async (token) => {
    const { listaRepresLegal } = await repLegalesListar({tokenCompañia: token})
    // console.log(listaRepresLegal)

    setListaRepLegal(listaRepresLegal)
    // console.log(listaRepLegal)
  }

  useEffect(() => {
    getDataSelector();
    if(token){
      getDataRepresLegal(token)
    }
  }, []);

  
  

  return (
    <DataContext.Provider
      value={{
        listaUbigeo,
        setListaUbigeo,
        listaTipoInmatriculacion,
        setListaTipoInmatriculacion,
        listaEstadoContrato,
        setListaEstadoContrato,
        listaTipoDocumentos,
        setListaTipoDocumentos,
        listaTipoEstadoCivil,
        setListaTipoEstadoCivil,
        listaTipoContratante,
        setListaTipoContrate,
        listaEstado,
        setListaEstado,
        listaTipoMoneda,
        setListaTipoMoneda,
        listaBancos,
        setListaBancos,
        listaOficinasReg,
        setListaOficinasReg,
        listaCompradoresContext,
        setListaCompradoresContext,
        listaPuertos, 
        setListaPuertos,
        listaRegimenes, 
        setListaRegimenes,
        listaRepLegal,
        getDataRepresLegal,
        listaEstadosPersona, 
        setListaEstadosPersona,
        listaCombustible, setListaCombustible,
        listaOperaciones, setListaOperaciones
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
