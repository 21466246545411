import { Buffer } from "buffer";
import CryptoJS from "crypto-js"
import { keyCryptoJS } from "../config/keyCrypto"; 
export const soloNumeros = (event, length, maxLength) => {
  const existSelection = event.target.selectionStart !== event.target.selectionEnd
  const keysExeption = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Shift', 'Home', 'End', 'Delete', 'Ctrl', 'v', 'V', '.']
  if (length && maxLength) {
    if (length >= maxLength && !keysExeption.includes(event.key) && !existSelection) {
      event.preventDefault()
    }
  }
  if (!keysExeption.includes(event.key) && Number.isNaN(parseInt(event.key))) {
    event.preventDefault()
  }
}
export const inputNumber2 = (event, floating, maxLength) => {
  const value = event.target.value;
  const keyCode = event.keyCode;
  const length = event.target.value.length;
  if (keyCode === 8 || keyCode === 9 || keyCode === 16 || keyCode === 17 || keyCode === 27 || (keyCode >= 35 && keyCode <= 40) || keyCode === 46) {
    return;
  }
  if (event.ctrlKey && (keyCode === 67 || keyCode === 86)) {
    return;
  }
  const allowedKeys = (keyCode >= 48 && keyCode <= 57) ||
    (keyCode >= 65 && keyCode <= 90) ||
    (keyCode >= 96 && keyCode <= 105);
  if (!allowedKeys) {
    event.preventDefault();
  }
  if (maxLength && length >= maxLength) {
    event.preventDefault();
  } else {
    if (floating && (keyCode === 110 || keyCode === 190)) {
      return;
    }
  }
  if (value.trim().length === 0) {
    event.target.value = '';
  }
};

export const inputNumber = (event, floating, maxLength, minLength) => {
  const value = event.target.value
  const keyCode = event.keyCode
  const length = event.target.value.length
  const num = event.key;
  if (keyCode === 8 || keyCode === 9 || keyCode === 16 || keyCode === 17 || keyCode === 27 || (keyCode >= 35 && keyCode <= 40) || keyCode === 46) {
    return;
  }
  if (event.ctrlKey && (keyCode === 67 || keyCode === 86)) {
    event.preventDefault();
  }
  if (maxLength && length >= maxLength) {
    event.preventDefault()
  } else {
    if (floating && (keyCode === 110 || keyCode === 190)) {
      return;
    }
    if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
      event.preventDefault()
    }
  }
};

export const inputNumberDNI = (event) => {
  const inputField = event.target;
  if (/[^0-9]/.test(inputField.value)) {
    inputField.value = inputField.value.replace(/[^0-9]/g, '');
  }
  const keyCode = event.keyCode; 
  
  if (
    keyCode === 8 || keyCode === 9 || keyCode === 16 || keyCode === 17 ||
    keyCode === 27 || (keyCode >= 35 && keyCode <= 40) || keyCode === 46
  ) {
    return;
  }
  if (event.ctrlKey && (keyCode === 67 || keyCode === 86)) {
    event.preventDefault();
    return;
  }
  if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
    event.preventDefault();
    return;
  }
};

export const obtenerNombreYArchivoBase64 = (file) => {
  return new Promise((resolve) => {
    const nombreArchivo = file?.name || "";
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Value = e.target.result;
        resolve({
          nombreArchivo: nombreArchivo,
          archivoBase64: base64Value,
        });
      };
      reader.readAsDataURL(file);
    } else {
      resolve({
        nombreArchivo: nombreArchivo,
        archivoBase64: null,
      });
    }
  });
};

export const convertBase64 = (file) => {
  const nombreArchivo = file?.name || ""; 
  
  const extension = file?.name.split('.').pop();
  if (file) {
    return new Promise(async (resolve, reject) => {
      const ab = await file.arrayBuffer();
      const base64 = Buffer.from(ab).toString("base64");
      resolve({
        nombreArchivo: nombreArchivo,
        archivoBase64: base64,
        extension:extension
      });
    });
  } else {
    return {
      nombreArchivo: nombreArchivo,
      archivoBase64: null,
      extension:null
    }
  }
};

export const guardarLocalStorage = (obj, key) => {
  sessionStorage.setItem(key, JSON.stringify(obj));
};

export const obtenerLocalStorage = (key) => {
  const datosLSStr = sessionStorage.getItem(key);
  const datosLSObj = JSON.parse(datosLSStr);
  return datosLSObj;
};

export const limpiarLocalStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const formatearFecha = (fecha) => {
  const dia = fecha.getDate().toString().padStart(2, "0");
  const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
  const año = fecha.getFullYear();
  return `${dia}/${mes}/${año}`;
};

export const descargarArchivoDesdeBase64 = (base64Content, nombreArchivo) => {
  const enlaceDescarga = document.createElement("a");
  const binaryData = atob(base64Content);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    view[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);
  enlaceDescarga.href = url;
  enlaceDescarga.download = nombreArchivo;
  enlaceDescarga.click();
  window.URL.revokeObjectURL(url);
};

export const obtenerLabel = (lista, value) => {
  if (value !== "") {
    const label =
      lista?.find((d) => d.EstContCod === value)?.EstContDes ||
      lista?.find((d) => d.PerEstCod === value)?.PerEstDes ||
      lista?.find((d) => d.TipDocCod === value)?.TipDocDes ||
      lista?.find((d) => d.TUbiCod === value)?.TUbiDes ||
      "";
    return label;
  }
};

const root = document.documentElement;
export const cambiarColores = (variables) => {
  variables.forEach((variable) => {
    const { name, color } = variable;
    root.style.setProperty(name, color);
  });
};

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._ñÑ-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email.trim());
}

export const encriptar = (objeto) => {
  try {
    const objetoSerializado = JSON.stringify(objeto);
    const textoCifrado = CryptoJS.AES.encrypt(
      objetoSerializado,
      keyCryptoJS
    ).toString();
    return textoCifrado;
  } catch (error) {
    return null;
  }
};
export const desencriptar = (textoCifrado) => {
  try {
    const bytes = CryptoJS.AES.decrypt(textoCifrado, keyCryptoJS);
    const objetoSerializado = bytes.toString(CryptoJS.enc.Utf8);
    const objetoDeserializado = JSON.parse(objetoSerializado);
    return objetoDeserializado;
  } catch (error) {
    return null;
  }
};
export const inputTextDomicilioRazonSocial = (event) => {
  const num = event.target.value
  const forbiddenCharacters = /[~'`;\[\]:"?¿><,\/\[\]\-=+/*+\\_|{}¡!@#$%^*°()]/g;
  const handleInput = (event) => {
    let newValue = event.target.value;
    if (forbiddenCharacters.test(num)) {
      event.target.value = num.replace(forbiddenCharacters, '');
    }
    if (newValue.includes('´')) {
      newValue = newValue.replace('´', '');
    } else {
      newValue = newValue.replace('¨', '');
    }
    newValue = newValue.replace(forbiddenCharacters, '');
    if (newValue !== event.target.value) {
      event.target.value = newValue;
    }
  };
  if (!event.target.inputHandlerAdded) {
    event.target.addEventListener('input', handleInput);
    event.target.inputHandlerAdded = true;
  }
  if (forbiddenCharacters.test(num)) {
    event.target.value = num.replace(forbiddenCharacters, '');
  }
  if (num.trim().length === 0) {
    event.target.value = '';
  }
}
export const inputText = (event) => {
  const keyCode = event.keyCode || event.which;
  const char = String.fromCharCode(keyCode);
  const value = event.target.value;
  if (value.trim().length === 0) {
    event.target.value = '';
  }
  // const forbiddenCharacters = /[~'`.;\[\]:"?¿><,\/\[\]\-=+/*-+\\_|{}¡!@#$%^&*°()0-9]/g;
  const forbiddenCharacters = /[~`.;\[\]:"?¿><,\/\[\]\=+/*+\\_|{}¡!@#$%^&*°()0-9]/g
  const handleInput = (event) => {
    
    let newValue = event.target.value;
    if (forbiddenCharacters.test(value)) {
      event.target.value = value.replace(forbiddenCharacters, '');
    }
    
    if (newValue.includes('´')) {
      newValue = newValue.replace('´', '');
    } else {
      newValue = newValue.replace('¨', '');
    }
    newValue = newValue.replace(forbiddenCharacters, '');
    if (newValue !== event.target.value) {
      event.target.value = newValue;
    }

  };
  if (!event.target.inputHandlerAdded) {
    event.target.addEventListener('input', handleInput);
    event.target.inputHandlerAdded = true;
  }


  if (keyCode === 8 || keyCode === 9 ||  keyCode === 17 || keyCode === 27 || (keyCode >= 32 && keyCode <= 40) || keyCode === 46 || keyCode === 45 || keyCode === 192) {  // aceptamos letra shift keycode 16
    return;
  }
  if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 109)) {
    event.preventDefault();
    return;
  }
  if (event.ctrlKey && (keyCode === 67 || keyCode === 86)) {
    event.preventDefault();
    return;
  }
  
  /*const allowedCharacters = /^[a-zA-ZáéíóúÁÉÍÓÚ\b\t\n\r\x1b\x7f]+$/;
  if (!allowedCharacters.test(char)) {
    event.preventDefault();
    return;
  }
*/
  
}
export const isJustLAndN = (event) => {
  const inputField = event.target;
  const num = inputField.value;
  const forbiddenCharacters = /[^a-zA-Z0-9]/g;
  inputField.addEventListener('input', () => {
    if (forbiddenCharacters.test(inputField.value)) {
      inputField.value = inputField.value.replace(forbiddenCharacters, '');
    }
    if (inputField.value.trim().length === 0) {
      inputField.value = '';
    }
  });
  if (event.key === '´') {
    event.preventDefault();
  }
};
export const isJustLAndN2 = (event) => {
  const num = event.key;
  const keyCode = event.keyCode;
  const completeString = event.target.value;
  const char = String.fromCharCode(keyCode);
  const forbiddenCharacters = /[~'`;\[\]:°"¿?><.,\/\[\]\-=+/*-+\\_|{}¡!@#$%^&*()ñÑáéíóúÁÉÍÓÚÝý]/;
  const handleInput = (event) => {
    let newValue = event.target.value;
    if (forbiddenCharacters.test(completeString)) {
      event.target.value = completeString.replace(forbiddenCharacters, '');
    }
    if (newValue.includes('´')) {
      newValue = newValue.replace('´', '');
    } else {
      newValue = newValue.replace('¨', '');
    }
    newValue = newValue.replace(forbiddenCharacters, '');
    if (newValue !== event.target.value) {
      event.target.value = newValue;
    }
  };
  if (!event.target.inputHandlerAdded) {
    event.target.addEventListener('input', handleInput);
    event.target.inputHandlerAdded = true;
  }
  if (completeString.trim().length === 0) {
    event.target.value = '';
  }
  if (keyCode === 8 || keyCode === 9 || keyCode === 16 || keyCode === 17 || keyCode === 27 || (keyCode >= 32 && keyCode <= 40) || keyCode === 46 || keyCode === 45 || keyCode === 96) {
    return;
  }
  if (forbiddenCharacters.test(num)) {
    event.preventDefault();
  }
  if (event.ctrlKey && (keyCode === 67 || keyCode === 86)) {
    event.preventDefault();
  }
  const allowedCharacters = /^[a-zA-Z0-9\b\t\n\r\x1b\x7f]+$/;
  if (!allowedCharacters.test(char)) {
    event.preventDefault();
    return;
  }
  if (completeString.length > 0) {
    event.target.value = completeString.trim();
  }
};

export const inputSoloNumeros = (event) => {
  // Expresión regular que permite números, letras (incluyendo ñ y Ñ) y espacios
  const forbiddenCharacters = /[^0-9a-zA-ZñÑ\s]/g; 
  const handleInput = (event) => {
      let newValue = event.target.value;
      // Reemplaza caracteres que no sean números, letras o espacio con una cadena vacía
      newValue = newValue.replace(forbiddenCharacters, '');
      // Actualiza el valor del campo solo si ha cambiado
      if (newValue !== event.target.value) {
          event.target.value = newValue;
      }
  };

  if (!event.target.inputHandlerAdded) {
      event.target.addEventListener('input', handleInput);
      event.target.inputHandlerAdded = true;
  }

  // Inicializa el valor del campo para que solo tenga números, letras o espacios
  const num = event.target.value;
  if (forbiddenCharacters.test(num)) {
      event.target.value = num.replace(forbiddenCharacters, '');
  }

  if (event.target.value.trim().length === 0) {
      event.target.value = '';
  }
};


